<script lang="jsx">
export default {
  name: 'CartReducePriceIcon',
  functional: true,
  props: {
    width: {
      type: [Number, String],
      default: '12'
    },
    height: {
      type: [Number, String],
      default: '12'
    },
    isRw: {
      type: Boolean,
      default: false
    },
    isNewCartrowVision: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props }) {
    const { width, height, isRw, isNewCartrowVision } = props
    const fillColor = isRw ? '#B40954' : '#C44A01'

    return (
      <div
        class={['reduce-price-icon', { 'new-cartrow-vision': isNewCartrowVision }]}
        style={{ width: width + 'px', height: height + 'px' }}
      >
        <svg
          width={width}
          height={height}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 3H8V7H4V3Z"
            fill={fillColor}
          />
          <path
            d="M4 2H8V1H4V2Z"
            fill={fillColor}
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6 11L2 6.875H10L6 11Z"
            fill={fillColor}
          />
        </svg>
      </div>
    )
  }
}
</script>

<style lang="less">
.reduce-price-icon{
  display: inline-flex;
  position: relative;
  margin-right: 4/75rem;
  vertical-align: bottom;
  &.new-cartrow-vision {
    margin-right: 0;
  }
}
</style>
