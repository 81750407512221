<template>
  <div class="item-lowinventory-wrapper">
    <div
      class="item-lowinventory-info"
      @click="onClickEntry"
    >
      <div class="content">
        <div
          v-if="imgUrl"
          class="lowinventory-img"
        >
          <img
            class="lazyload"
            :src="locals.LAZY_IMG"
            :data-src="imgUrl"
            :alt="imgUrl"
          />
        </div>
        <div
          v-html="tip"
          class="lowinventory-tip"
        ></div>
      </div>
      <sui_icon_more_right_12px
        name="sui_icon_more_right_12px"
        size="12px"
        :is-rotate="locals.GB_cssRight"
        color="#222"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'

defineProps({
  imgUrl: {
    type: String,
    default: ''
  },
  tip: {
    type: String,
    default: ''
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

const emits = defineEmits(['open'])

function onClickEntry() {
  emits('open')
}

</script>
<style lang="less" scoped>
.item-lowinventory-wrapper {
  width: 100%;
  background-color: #fff;
  padding-bottom: 20/75rem;
}
.item-lowinventory-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8/75rem;
  gap: 8/75rem;
  background: #F6F6F6;
  margin-left: 80/75rem;
  margin-right: 20/75rem;
  height: 72/75rem;
  .lowinventory-img {
    width: 56/75rem;
    height: 56/75rem;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
  }
  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8/75rem;
  }
}
</style>
