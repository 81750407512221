<template>
  <div
    :class="{
      'filter-sticky': isSticky || showScanLights,
    }"
    :style="{ 'top': isSticky || showScanLights ? stickyTop + 'px' : 0 }"
  >
    <div class="cart-list-filter_container">
      <div
        class="cart-list-filter j-cart-list-filter" 
      >
        <s-checkbox-group
          v-model="checkValue"
          margin-r="0.213rem"
          class="check-groups"
        >
          <FilterCheckTag
            v-if="filterLabels.length >= 1"
            :abtStyle="filterControlStyle"
            :label="{
              tip: language.SHEIN_KEY_PWA_31180,
              type: 'none',
            }"
            :checked="modelValue === 'none'"
            :showPrefixIcon="false"
            :showSuffixIcon="false"
            :showDivider="false"
            :cancelMark="false"
          />
          <FilterCheckTag
            v-for="(labelItem, index) in filterLabels"
            :key="labelItem.type + index"
            ref="refLabel"
            v-tap="sendDaEvent(labelItem, 'default_outside')"
            :abtStyle="filterControlStyle"
            :label="labelItem"
            :checked="getTagChecked(labelItem)"
            :showPrefixIcon="true"
            :showSuffixIcon="true"
            :showScanLights="showScanLights"
            :total="showNumber ? filteredNumber : 0"
          />
          <!-- 多标签收纳情况下的外围机动坑位 -->
          <FilterCheckTag
            v-if="dynamicLabel.type"
            :abtStyle="filterControlStyle"
            :label="dynamicLabel"
            :checked="getTagChecked(dynamicLabel)"
            prefixIcon="sui_icon_filter_16px"
            :showPrefixIcon="true"
            :showSuffixIcon="true"
            :showDivider="true"
            :total="showNumber ? filteredNumber : 0"
          />
          
          <!-- more coupons, 点击打开券助手 -->
          <div
            v-if="showMoreCoupon && !showMorePop"
            class="more-coupon"
            @click="showCouponHelper"
          >
            <span>{{ language.SHEIN_KEY_PWA_30092 }}</span>
            <Icon
              name="sui_icon_more_right_12px_2"
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </s-checkbox-group>
      </div>
      <div
        v-if="showMorePop"
        ref="FilterMoreEntry"
        v-expose="{ id: '1-8-3-55' }"
        v-tap="{ id: '1-8-3-56' }"
        class="more-entry"
        :class="{
          'acitived': slideShow,
          'style-a_entry': filterControlStyle === 'A',
        }"
        @click="toggleFilterMore"
      >
        <div
          v-if="filterSelectLogo == 'text'"
          class="text-entry"
        >
          <span>
            {{ language.SHEIN_KEY_PWA_31181 }}
          </span>
          <Icon
            class="text-icon"
            size="16px"
            is-responsive-name
            :name="slideShow ? 'sui_icon_more_up_16px_2' :'sui_icon_more_down_16px_2'"
          />
        </div>
        <div
          v-if="filterSelectLogo == 'logo'"
          class="logo-entry"
        >
          <Icon
            name="sui_icon_filter_16px"
            size="16px"
            class="logo-icon"
            :is-rotate="locals.GB_cssRight"
          />
        </div>
      </div>
    </div>
    <s-slide
      v-click-away="onClickAway"
      :visible.sync="slideShow"
      :modal="true"
      @opened="analysis18324(labelsInMorePop, 'filter_menu_inside')"
    >
      <div 
        ref="slidePanel"
        class="slide-panel"
      >
        <div
          v-for="(item, i) in categoryLabels"
          :key="i"
          class="category-filter"
        >
          <div class="category-title">
            <span class="text">{{ item.title }}</span>
            <span 
              v-if="showMoreCoupon && item.isCouponCategory"
              class="all-coupon" 
              @click="showAllCoupon"
            >
              {{ language.SHEIN_KEY_PWA_31085 }} >
            </span>
          </div>
          <s-checkbox-group
            v-model="checkValue"
            @change="slideTagsChange"
          >
            <FilterCheckTag
              v-for="(labelItem, index) in item.list"
              :key="labelItem.type + index"
              v-tap="sendDaEvent(labelItem, 'filter_menu_inside')"
              class="check-tag"
              :abtStyle="filterControlStyle"
              :label="labelItem"
              :checked="getTagChecked(labelItem)"
              :showPrefixIcon="false"
              :showSuffixIcon="true"
              :inModal="true"
            />
          </s-checkbox-group>
        </div>
        <div
          v-if="showMoreCoupon && !hasCouponCategory"
          class="coupons-tips"
        >
          <Icon 
            name="sui_icon_info_12px_2" 
            size="14px"
            color="#959595"
          />
          <span class="tips-text">
            {{ language.SHEIN_KEY_PWA_31084 }}
          </span>
          <span 
            class="all-coupon"
            @click="showAllCoupon"
          >
            {{ language.SHEIN_KEY_PWA_31085 }}>
          </span>
        </div>
      </div>
    </s-slide>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { throttle } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { isElementPartiallyInViewport } from 'public/src/pages/cartNew/utils/index.js'
import { categoryMap, getHighWeightGoods, LabelKeyMap } from 'public/src/pages/cartNew/utils/cartFilterLabels.js'
import FilterCheckTag from './FilterCheckTag'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ClickAway from 'public/src/pages/cartNew/directives/clickAway.js'
export default {
  components: {
    Icon,
    FilterCheckTag,
  },
  directives: {
    ClickAway
  },
  props: {
    goodsFilterLabels: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [Boolean, Array, String],
      default: false
    },
    showNumber: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      valueByLocal: null, // local cache, try use cache first
      cartHeaderDom: null,
      resizeObserver: null,
      stickyTop: 0,
      downScrolling: false,
      lastScrollTop: 0,
      mutationObserver: null,
      showScanLights: false, // 是否播放扫光动画
      slideShow: false,
      filterSplitIndex: 0,
      filteredDoms: [], // 已选中筛选标签的商品行Dom
      filteredNumber: 0, // 已选中筛选标签的商品行数量
    }
  },
  computed: {
    ...mapState('cart', [
      'language',
      'locals',
      'couponInfo',
      'dynamicLabel',
      'cartLeave',
      'cartItemMap',
      'integrityPromotionInfo',
      'inducementSortTypes',
    ]),
    ...mapGetters('cart', [
      'filterControlStyle',
      'isFilterControlTop',
      'couponSelectType',
      'filterSelectLogo',
      'showFilteredInformation',
    ]),
    checkValue: {
      get() {
        if (this.valueByLocal) {
          return [this.valueByLocal]
        }
        return [this.modelValue]
      },
      set(value) {
        let newValue = value[value.length - 1]
        if(newValue == this.modelValue || !newValue) newValue = 'none'
        this.valueByLocal = newValue
        this.$emit('update:modelValue', newValue)
      }
    },
    /**
     * 筛选栏置顶逻辑:
     * 1. 进行筛选后，筛选栏常驻置顶，取消筛选 页面回到顶部，重新获取数据
     * 2. abt参数control_top为on且没有进行筛选：
     *   2.1 向上滑动，收起筛选栏（不吸顶）
     *   2.2 向下滑动，展开筛选栏（吸顶）
     */
    isSticky() {
      return this.checkValue != 'none' || this.downScrolling
    },
    /**
     * show coupons展示逻辑
     * 设置了abt参数couponSelectType为select
     * 券助手接口有数据
     */
    showMoreCoupon() {
      let { usableCouponList = [], disabledCouponList = [] } = this.couponInfo || {}
      const coupons = [...usableCouponList, ...disabledCouponList]
      return this.couponSelectType == 'select' && coupons?.length > 0
    },
    labelsInFirstView() {
      if (!this.needSplitLabel) return []
      return this.goodsFilterLabels?.slice(0, this.filterSplitIndex) || []
    },
    labelsInMorePop() {
      if (!this.needSplitLabel) return []
      return this.goodsFilterLabels?.slice(this.filterSplitIndex) || []
    },
    showMorePop() {
      return this.needSplitLabel && this.labelsInMorePop.length
    },
    filterLabels() {
      if (this.labelsInFirstView?.length) return this.labelsInFirstView
      return this.goodsFilterLabels || []
    },
    // 按类别组装筛选标签
    categoryLabels() {
      const arr = []
      for (const key in categoryMap) {
        const { langKey, labelTypes } = categoryMap[key]
        arr.push({
          title: this.language[langKey],
          isCouponCategory: key == 'COUPON_ELIGIBLE',
          labelTypes,
          list: [],
        })
      }

      this.labelsInMorePop.forEach(label => {
        const arrItem = arr.find((v) => v.labelTypes.includes(label?.type))
        if (arrItem) arrItem?.list?.push(label)
      })

      return arr.filter((v) => v?.list?.length > 0)
    },
    // 收否有券筛选分类
    hasCouponCategory() {
      return this.categoryLabels.some((v) => v.isCouponCategory)
    },
    needSplitLabel() {
      return ['logo', 'text'].includes(this.filterSelectLogo)
    }
  },
  watch: {
    modelValue(val) { // update by outside, clear local cache 
      this.clear()
      this.scrollToTarget()
      if (val !== 'none') {
        this.getFilteredNumber()
      }
    }
  },
  async mounted() {
    this.handleFilterCouponGuide()
    this.calcStickyTop()
    if (this.isFilterControlTop && window) {
      window.addEventListener('scroll', this.handleScroll)
    }
    
    this.splitLabels()
    this.analysis18324(this.filterLabels, 'default_outside')
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.cartHeaderDom)
    this.resizeObserver = null
    window.removeEventListener('scroll', this.handleScroll)
    this.showScanLights = false
  },
  methods: {
    ...mapMutations('cart', ['toggleDrawer', 'updateState']),
    ...mapActions('cart', ['sortCartInducementInfo']),
    clear() {
      this.valueByLocal = null
    },
    // 筛选栏吸顶高度取决于j-cart-header + cart-header-wrapper的高度
    calcStickyTop() {
      const _t = this
      if (this.resizeObserver) return
      this.$nextTick(() => {
        const promotionHeight = document.querySelector('.cart-header-wrapper')?.clientHeight || 0
        _t.cartHeaderDom = document.querySelector('.j-cart-header')
        _t.resizeObserver = new ResizeObserver((entries) => {
          if(_t.cartLeave) return
          for (let entry of entries) {
            _t.stickyTop = entry?.contentRect?.height + promotionHeight || 0
          }
        })
        _t.resizeObserver.observe(_t.cartHeaderDom)
      })
    },
    // 获取页面滚动方向
    getScrollDirection() {
      const st = window.scrollY || document.documentElement.scrollTop
      let sDirection
      if (st > this.lastScrollTop) {
        sDirection = 'up'
      } else {
        sDirection = 'down'
      }
      this.lastScrollTop = st
      return sDirection
    },
    handleScroll: throttle({ 
      func: function() {
        const direction = this.getScrollDirection()
        this.downScrolling = direction == 'down'
      },
      wait: 300,
      options: {
        trailing: false
      }
    }),
    showCouponHelper() {
      this.toggleDrawer({ type: 'coupon-helper', value: true })
    },
    // 将选中的标签自动滚动至视口中心
    scrollToTarget() {
      const labelDom = this.$refs['refLabel']
      const containerDom = $('.j-cart-list-filter')?.[0]

      // 如果选中的是all，滚动至最左侧
      if (this.modelValue == 'none') {
        containerDom?.scrollTo({ left: 0, behavior: 'smooth' })
        return
      }
      // 如果选中了机动位筛选标签
      if (this.isClickDynamicLabel()) {
        this.scrollToRight()
        return
      }
      
      const targetIndex = this.filterLabels?.findIndex(
        l => this.isCoupon(l) ? l.id == this.modelValue : l.type == this.modelValue
      )
      if (targetIndex < 0) return
      const targetDom = labelDom[targetIndex].$el
      // 获取标签容器和当前标签的位置信息
      const containerRect = containerDom.getBoundingClientRect()
      const targetRect = targetDom.getBoundingClientRect()
      // 计算滚动位置
      let scrollLeft = targetDom.offsetLeft - (containerRect.width - targetRect.width) / 2
      // 如果没有足够的滚动空间，则滚动至最右边
      if (scrollLeft + targetDom.offsetWidth > containerDom.scrollWidth) {
        scrollLeft = containerDom.scrollWidth - containerDom.offsetWidth
      }
      // 滚动至计算出的位置
      containerDom.scrollTo({ left: scrollLeft, behavior: 'smooth' })
    },
    isClickDynamicLabel() {
      if (!this.dynamicLabel.type) return
      return this.modelValue == this.dynamicLabel[this.isCoupon(this.dynamicLabel) ? 'id' : 'type']
    },

    // 券筛选新人指引
    async handleFilterCouponGuide() {
      if (this.couponSelectType == 'couponhelper') return // 未开启券筛选
      if (typeof window != 'undefined') {
        const isVisited = window.localStorage.getItem('filterCouponGuide')
        if (isVisited) return
        this.$nextTick(() => {
          // 当其他蒙层类新人指引（分享、到手价）完成，再触发券筛选动画逻辑
          const discountNewGuide = window.localStorage.getItem('DiscountNewGuide')
          const newShareGuideV2 = window.localStorage.getItem('newShareGuideV2')
          if (discountNewGuide && newShareGuideV2) {
            this.initAnimation()
          } else {
            // 如果同时触发其他新人指引，需要监听其新人指引已完成，即蒙层消失
            const cartContainer = $('.j-cart-container')
            const otherGuideClassName = ['share-guide-wrapper', 'discount-guide-wrapper']
            this.mutationObserver = new MutationObserver((mutations) => {
              mutations.forEach((mutation) => {
                const removedNodes = mutation.removedNodes
                if (removedNodes && removedNodes.length > 0
                  && otherGuideClassName.includes(removedNodes[0].className)
                ) {
                  this.initAnimation() // 目标元素消失，执行相应操作
                }
              })
            })
            this.mutationObserver.observe(cartContainer[0], { childList: true })
          }
        })
      }
    },

    initAnimation() {
      // 已进入券筛选动画逻辑，取消mutationObserver监听
      this.mutationObserver = null

      this.$nextTick(() => {
        const labelDom = $('.j_coupon-label')
        if (!labelDom) return
        // 检测是否有券筛选在视口中
        let inView = []
        labelDom.forEach(element => {
          const rect = element.getBoundingClientRect()
          if (rect.left >= 0 && rect.left <= window.innerWidth) {
            inView.push(element)
          }
        })
        // 如果视口中没有券，执行自动滚动露出一张券
        if (!inView.length) {
          const firstCoupon = $('.j_coupon-label')[0]
          if (!firstCoupon) return
          firstCoupon.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
        // 为每一张券加扫光动画, 在进行扫光动画时，筛选栏吸顶，3s后恢复原状态
        this.showScanLights = true
        setTimeout(() => {
          this.showScanLights = false
        }, 3000)
        window.localStorage.setItem('filterCouponGuide', true)
      })
    },
    getTagChecked(label) {
      return this.checkValue == 'none'
        ? false
        : this.isCoupon(label)
          ? this.checkValue == label.id
          : this.checkValue == label.type
    },
    splitLabels() {
      if (!this.needSplitLabel) return
      this.$nextTick(() => {
        const labelDom = this.$refs['refLabel']
        if (!labelDom?.length) return
        const splitIndex = labelDom.findIndex((v) => !isElementPartiallyInViewport(v.$el))
        this.filterSplitIndex = splitIndex <= -1 ? labelDom.length : splitIndex
      })
    },
    handleDynamicLabel(val) {
      const dynamicVal = val[val.length - 1]
      if (dynamicVal === 'none') return
      const targetLabel =  this.labelsInMorePop.find(l => dynamicVal === l[!isNaN(dynamicVal) ? 'id' : 'type'])
      this.updateState({ key: 'dynamicLabel', value: targetLabel || {} })
      if (targetLabel?.type) {
        this.scrollToRight()
      }
    },
    scrollToRight() {
      setTimeout(() => {
        // 如果选中机动位，自动滚动至最右侧
        const containerDom = $('.j-cart-list-filter')?.[0]
        containerDom.scrollTo({ left: containerDom.offsetWidth, behavior: 'smooth' })
      }, 0)
    },
    toggleFilterMore() {
      this.slideShow = !this.slideShow
      if (this.slideShow) {
        this.$refs.slidePanel?.scrollTo({ top: 0 })
      }
    },
    slideTagsChange(v) {
      this.handleDynamicLabel(v)
      this.closeFilterMore()
    },
    closeFilterMore() {
      this.slideShow = false
    },
    onClickAway(event) {
      if (!this.slideShow) return
      // 如果点了More则不处理任何事
      if (this.$refs.FilterMoreEntry === event.target || this.$refs.FilterMoreEntry?.contains(event.target)) return
      this.closeFilterMore()
    },
    showAllCoupon() {
      this.closeFilterMore()
      this.showCouponHelper()
    },
    sendDaEvent(label, showType) {
      const unChecked = this.modelValue == label[this.isCoupon(label) ? 'id' : 'type']
      const label_id = this.isCoupon(label) ? label.couponType : label.type
      return {
        id: '1-8-3-25',
        data: {
          label_id: label_id,
          is_cancel: unChecked ? 0 : 1,
          show_type: showType
        },
      }
    },
    analysis18324 (labels, show_type) {
      const label_id = labels.map((v) => v.type === 'coupon' ? v.couponType : v.type).join(',')
      daEventCenter.triggerNotice({
        daId: '1-8-3-24',
        extraData: {
          label_id,
          show_type,
        }
      })
    },
    isCoupon(label) {
      return label.type == 'coupon'
    },
    // 获取当前选中标签的商品行数量
    getFilteredNumber() {
      const allCartLineDoms = document.querySelectorAll('.j-valid-cart-item')
      this.filteredDoms = Array.from(allCartLineDoms).filter(div => getComputedStyle(div)?.display !== 'none')
      this.filteredNumber =  this.filteredDoms.length
      this.handleGoodsTipShow()
    },
    // 高优商品提示逻辑
    handleGoodsTipShow() {
      // abt开启，cartSelect.param.selectinform
      if (!this.showFilteredInformation) return
      // 选中标签为【降价、闪购10、限时折扣3、打折限购24】
      const tags = [LabelKeyMap.MARKDOWNS, LabelKeyMap.LIMIT_OFF, LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT]
      if (!tags.includes(this.modelValue)) return
      // 筛选的商品数量大于4
      if (this.filteredNumber <= 4) return
      // 存在高优商品 且 高优商品未在首屏展示
      this.checkHighWeightGoods()
    },
    checkHighWeightGoods() {
      // 获取筛选出的商品列表
      let filteredGoodsList = []
      this.filteredDoms.forEach(cartItemDom => {
        const id = cartItemDom.getAttribute('data-cart-id')
        filteredGoodsList.push(this.cartItemMap[id])
      })
      // 获取高优商品
      const targetGoods = getHighWeightGoods(this.modelValue, filteredGoodsList, this.integrityPromotionInfo)
      if (!targetGoods) return
      // 判断是否在第一屏
      const targetDom = document.querySelector(`[data-cart-id="${targetGoods.id}"]`)
      const rect = targetDom.getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const bottomHeight = document.querySelector('.j-cart-bottom')?.clientHeight
      const inFirstView = rect.bottom <= (windowHeight - bottomHeight)
      // 不在第一屏，满足展示高优商品提示条件，更新高优商品信息
      if (!inFirstView) {
        this.updateState(({
          key: 'filterHighWeightGoods',
          value: targetGoods
        }))
        this.$emit('update-goods-tip-show', true)
        // 与利诱点气泡互斥(降价、闪购)
        if (this.modelValue === LabelKeyMap.FLASH_SALE) {
          this.updateIncentiveType('flash_sale')
        } else if (this.modelValue === LabelKeyMap.MARKDOWNS) {
          this.updateIncentiveType('mark_down')
        }
      }
    },
    // 处理利诱点气泡数据
    updateIncentiveType(type) {
      const validIncentiveType = this.inducementSortTypes?.filter(t => t !== type)
      this.updateState({
        key: 'inducementSortTypes',
        value: validIncentiveType
      })
      this.sortCartInducementInfo()
    },
  },
}
</script>

<style lang="less" scoped>
.cart-list-filter_container {
  width: 100%;
  display: flex;
  background: #fff;
}
.more-entry {
  width: initial;
  padding: 6/37.5rem 10/37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: -5/37.5rem 0 10/37.5rem -5/37.5rem rgba(204, 204, 204, 0.5);
  &.acitived {
    .text-entry {
      span, .text-icon { /* stylelint-disable-line */
        color: #000 !important;/* stylelint-disable-line declaration-no-important */
      }
    }
    .logo-entry {
      border-color: #000;
    }
    .logo-icon {
      color: #000!important;/* stylelint-disable-line declaration-no-important */
    }
  }
  
  &.acitived.style-a_entry {
    .text-entry { /* stylelint-disable-line */
      span, .text-icon { /* stylelint-disable-line */
        color: #FA6338 !important;/* stylelint-disable-line declaration-no-important */
      }
    }

    .logo-entry { /* stylelint-disable-line */
      border-color: #FA6338;
    }
    .logo-icon { /* stylelint-disable-line */
      color: #FA6338!important;/* stylelint-disable-line declaration-no-important */
    }
  }
}
.text-entry {
  display: flex;
  align-items: center;
  span {
    color: #666;
  }
  .text-icon {
    color: #bbb !important;/* stylelint-disable-line declaration-no-important */
  }
}
.logo-entry {
  padding: 0 10/37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27/37.5rem;
  height: 27/37.5rem;
  border-radius: 50%;
  border: solid 1px #E5E5E5;
  .logo-icon {
    color: #959595!important;/* stylelint-disable-line declaration-no-important */
  }
}
.cart-list-filter {
  flex: 1;
  padding: 20/75rem 0;
  white-space: nowrap;
  overflow-x: auto;
  border-bottom: solid .5px #E5E5E5;
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /*Chrome下隐藏滚动条，溢出可以透明滚动*/
  &::-webkit-scrollbar {
    display: none;
  }
}

.filter-sticky {
  position: sticky;
  z-index: 2;
}
.check-groups {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding: 0 32/75rem;
  .S-checkbox:last-child {
    margin-right: 0!important;/* stylelint-disable-line declaration-no-important */
  }
  .more-coupon {
    display: inline-flex;
    align-items: center;
    color: #2D68A8;
    font-size: 22/75rem;
  }
}

.slide-panel {
  background: #fff;
  max-height: 260/37.5rem;
  padding: 12/37.5rem; 
  overflow-y: auto;
  .all-coupon {
    color: #2D68A8;
    font-size: 12px;
    flex-shrink: 0;
  }
  .coupons-tips {
    display: flex;
    align-items: center;
    color: #979797;
    .tips-text {
      margin: 0 4/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.category-filter {
  &:not(:last-child) {
    margin-bottom: 12/37.5rem;
  }
  .category-title {
    display: flex;
    justify-content: space-between;
    height: 14px;
    line-height: 14px;
    .text {
      color: #222;
      font-size: 12px;
      font-weight: bold;
      margin-right: 16/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .check-tag {
    margin-top: 8/37.5rem;
    margin-right: 8/37.5rem !important;/* stylelint-disable-line declaration-no-important */
  }
}
</style>
