<template>
  <div
    v-expose="{
      id: '1-8-7-65',
      data: {
        order_id: order.billno,
        benefit_list: savedPrice ? 'discount' : 'common',
      }
    }"
    v-tap="getClickSaData('body')"
    class="cart-unpaid-tip"
    @click="handleViewOrder"
  >
    <imgStackGallery
      v-if="imgList.length"
      :img-list="imgList"
      :count="totalNums"
    />
    <div class="unpaid-content">
      <div class="content-up">
        <span class="unpaid-tip">{{ language.SHEIN_KEY_PWA_26690 }}</span>
        <span
          v-if="showCountDown"
          class="unpaid-countdown"
        >
          <sui_icon_hourglass_12px_2
            size="12px"
            color="#FA6338"
          />
          <count-down-time
            :time-stamp="order.expire_countdown"
            notReload
            notHide
            class="count-down"
            font-size="10px"
          />
        </span>
      </div>
      <div class="content-down">
        <span class="order-total">
          <span class="order-amount-tip">{{ language.SHEIN_KEY_PWA_30842 }}</span>
          <span class="order-amount">{{ ': ' + total }}</span>
        </span>
        <span
          v-if="savedPrice"
          class="order-saved"
        >
          <span class="order-split-line">|</span>
          <span class="order-saved-tip order-amount-tip">{{ language.SHEIN_KEY_PWA_30913 }}</span>
          <em class="order-saved-price order-amount">{{ ': ' + savedPrice }}</em>
        </span>
      </div>
    </div>
    <div
      v-tap="getClickSaData('view')"
      class="view-button"
      @click.stop="handleViewOrder"
    >
      <span class="button-text">{{ language.SHEIN_KEY_PWA_18965 }}</span>
      <sui_icon_more_right_12px_2
        size="12px"
        color="#fff"
      />
    </div>
    <div
      v-tap="getClickSaData('close')"
      class="close-button"
      @click.stop="handleClose"
    >
      <sui_icon_close_16px_2
        size="16px"
        color="#959595"
      />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineExpose, computed, onMounted, defineEmits } from 'vue'
import { getUnPaidImgs } from './unPaidOrderTools.js'
import imgStackGallery from './imgStackGallery.vue'
import { sui_icon_hourglass_12px_2, sui_icon_more_right_12px_2, sui_icon_close_16px_2 } from '@shein-aidc/icon-vue2'
import { CART_CLICK_UN_PAID_ORDER_INFO } from 'public/src/pages/cartNew/utils/constants.js'
const props = defineProps({
  order: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['update-order', 'close', 'jump-to-order'])
const imgList = computed(() => {
  return getUnPaidImgs(props.order?.order_goods_list || [])
})
const totalNums = computed(() => {
  let count = props.order?.order_goods_num
  if(props.order?.order_goods_list?.length == 1) return 0
  if(count > 99) return '99+'
  if(count > 1) return count // 仅有1张图不展示下标
  return 0
})
const total = computed(() => {
  return props.order?.currency_pay_amount?.amountWithSymbol
})
const savedPrice = computed(() => {
  if(props.order?.total_discount_new?.amount <= 0) return ''
  return props.order?.total_discount_new?.amountWithSymbol
})
const showCountDown = computed(() => {
  return props.order?.expire_countdown >= (new Date().getTime()) / 1000
})
function handleViewOrder() {
  const billno = props.order?.billno
  let queryParams = {
    from_type: 7
  }
  if(props.order?.payment_method != 'cod'){
    queryParams.show_selected_pay_method = 1
  }
  window.localStorage.setItem(CART_CLICK_UN_PAID_ORDER_INFO, 
    JSON.stringify({
      billno: props.order?.billno ?? '',
      timeStamp: Date.now(),
    })
  )
  emit('jump-to-order', billno, queryParams)
}
function handleClose() {
  emit('close')
}
function getClickSaData(type = '') {
  return {
    id: '1-8-7-66',
    data: {
      order_id: props.order?.billno,
      benefit_list: savedPrice.value ? 'discount' : 'common',
      button_type: type || 'body'
    }
  }
}
onMounted(() => {
  if(!props.order?.billno) {
    emit('update-order') // 客户端渲染时, 未支付订单信息为空时，触发更新
  }
})
defineExpose({
  imgList,
  totalNums
})
</script>
<style lang="less" scoped>
.cart-unpaid-tip {
  display: flex;
  padding: 2/75rem 24/75rem;
  height: 88/75rem;
  gap: 20/75rem;
  align-items: center;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%);
}
.unpaid-content {
  flex: 1;
  overflow: hidden;
}
.content-up, .content-down {
  display: flex;
  align-items: center;
  width: 100%;
}
.content-up {
  gap: 16/75rem;
  .unpaid-tip {
    font-size: 12px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .unpaid-countdown {
    flex-shrink: 0;
  }
}
.count-down {
  display: inline-block;
  margin-left: 4/75rem;
  color: @sui_color_discount;
  font-weight: 500;
  &::v-deep .count-number {
    background: unset;
  }
}
.content-down {
  font-size: 12px;
  margin-top: 4/75rem;
  .order-total {
    color: rgba(0, 0, 0, 0.6);
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    max-width: 100%;
  }
  .order-saved {
    color: @sui_color_discount;
    overflow: hidden;
    display: flex;
    .order-split-line {
      color: rgba(34, 34, 34, 0.30);
      margin-left: 12/75rem;
      margin-right: 12/75rem;
    }
    .order-saved-price {
      font-weight: 700;
    }
  }
  .order-amount-tip {
    flex-shrink: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 30/75rem;
  }
  .order-amount {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.view-button {
  display: flex;
  align-items: center;
  min-width: 80/75rem;
  max-width: 132/75rem;
  padding: 10/75rem 16/75rem;
  border-radius: 56/75rem;
  background: #FA6338;
  height: 48/75rem;
  .button-text {
    font-size: 11px;
    font-weight: 590;
    color: @sui_color_white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
