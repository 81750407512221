<template>
  <div
    v-if="cartsId.length"
    v-infinite-scroll="infiniteScroll"
    class="j-vue-container j-mshe-container-cart"
    :class="{'is-ssr': isSSR}"
    style="min-height: 1px;"
    :infinite-scroll-distance="infiniteScrollDistance"
    infinite-scroll-nodata="noMoreData"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
  >
    <CartListFilter
      v-if="cartsId.length > 4 && goodsFilterLabels.length > 0"
      v-show="!batchActive"
      ref="cartListFilter"
      :goods-filter-labels="goodsFilterLabels"
      :model-value="filterType"
      :show-number="showNumber"
      @update:modelValue="handleFilterClick"
      @update-goods-tip-show="updateGoodsTipShow"
    />
    <!-- 券筛选提示信息 -->
    <CartFilterCoupon
      v-if="!isNaN(filterType) && targetCouponInfo?.id"
      v-show="!batchActive"
    />
    <!-- 品类津提示信息 -->
    <CartFilterVoucherTips
      v-if="filterType == 'category_voucher' && isShowFilterTips && !batchActive"
      :tip-content="language.SHEIN_KEY_PWA_31147"
      @close="filterTipsClose"
    />
    <!-- 促销活动、商品属性类提示信息 -->
    <CartFilterGoodsTips v-if="showGoodsTip && filterHighWeightGoods?.id" />
    <EmbedLoading
      :show="embedLoadingShow"
      top
    />
    <template v-for="mallCartItem in mallCartList">
      <div
        :key="mallCartItem.mall_code"
        class="warehouse-group"
        :class="[
          isPlatForm? 'platform-warehouse-group':'noplatform-warehouse-group',
          {
            'noplatform-show-store': !isPlatForm && !cartShowStore,
            'warehouse-group__opt-bg': cartsId && cartsId.length < 3,
          }
        ]"
      >
        <img
          v-if="cartsId && cartsId.length < 3"
          class="opt-bg-img"
          src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAA0NDQ0ODQ4QEA4UFhMWFB4bGRkbHi0gIiAiIC1EKjIqKjIqRDxJOzc7STxsVUtLVWx9aWNpfZeHh5e+tb75+f8BDQ0NDQ4NDhAQDhQWExYUHhsZGRseLSAiICIgLUQqMioqMipEPEk7NztJPGxVS0tVbH1pY2l9l4eHl761vvn5///CABEIAJQBZgMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAAB//aAAgBAQAAAACkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAxAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//EABQQAQAAAAAAAAAAAAAAAAAAAJD/2gAIAQEAAT8AVf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAcP/aAAgBAgEBPwBm/8QAFBEBAAAAAAAAAAAAAAAAAAAAcP/aAAgBAwEBPwBm/9k="
        />
        <template v-if="isPlatForm">
          <platform-warehouse-header
            v-show="!batchActive && (!doFilter + showFilterHeader(mallCartItem, filterType, 'mall', targetCouponInfo, goodsFilterLabels))"
            :mall-code="mallCartItem.mall_code"
          >
            <s-checkbox
              v-show="!doFilter"
              :label="mallCartItem.mall_code"
              :value="isCheckedWarehouse(mallCartItem.mall_code)"
              :disabled="isDisabledWarehouse(mallCartItem.mall_code)"
              :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
              @change="handleWarehouseCheckedItem"
            />
          </platform-warehouse-header>
        </template>
        <template v-for="listItem in mallCartItem.mall_list">
          <!-- 泰国站等站点需要根据abt判断是否展示店铺信息, 因此不能仅依据数据来判断 -->
          <template v-if="cartShowStore && !cartStoreInRow && listItem.store_code">
            <platform-store-header
              v-show="!doFilter + showFilterHeader(( listItem.store_code ? listItem.store_list : [listItem]), filterType, 'store', targetCouponInfo, goodsFilterLabels)"
              :key="mallCartItem.mall_code +'-'+listItem.store_code"
              :store-code="listItem.storeCode"
              :store-logo="listItem.store_logo"
              :store-title="listItem.store_title"
              :store-type="listItem.store_type"
              :mall-code="mallCartItem.mall_code"
              :preferred-seller-store="listItem.preferred_seller_store"
              :store-list-origin="listItem.store_list_origin"
            >
              <s-checkbox
                v-show="!doFilter"
                :label="mallCartItem.mall_code +','+listItem.store_code"
                :value="isChecked(listItem.store_list_origin)"
                :disabled="isDisabled(listItem.store_list_origin)"
                :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
                @change="handleStoreCheckedItem"
              />
            </platform-store-header>
            <template v-if="listItem.innerUnGroupPromotions && listItem.innerUnGroupPromotions.length > 0">
              <cart-item-header-promotion
                v-for="storePromoHeader in listItem.innerUnGroupPromotions"
                v-show="!doFilter + showFilterHeader(( listItem.store_code ? listItem.store_list : [listItem]), filterType, 'store', targetCouponInfo, goodsFilterLabels)"
                :key="'store' + listItem.storeCode +storePromoHeader.promotion_id"
                :show-store-title="listItem.store_code === 'shein'"
                :integrity-promotion-info="storePromoHeader"
              />
            </template>
          </template>
          <template v-for="(promotionGroup, index) in (listItem.store_code ? listItem.store_list : [listItem])">
            <div
              v-if="index > 0"
              :key="mallCartItem.mall_code + '-' +(promotionGroup.promotion_id || promotionGroup.id) +'-'+ listItem.store_code"
              class="store-group-gap"
            ></div>
            <cart-item-header-promotion
              v-if="+promotionGroup.type_id && promotionGroup.id != 'common' && ![4,22].includes(+promotionGroup.type_id)"
              v-show="!doFilter + showFilterHeader(promotionGroup.list, filterType, targetCouponInfo, goodsFilterLabels)"
              :key="promotionGroup.promotion_id + '-' + listItem.store_code"
              :integrity-promotion-info="promotionGroup"
            >
              <s-checkbox
                v-show="!doFilter"
                :label="promotionGroup.promotion_id"
                :value="isChecked(promotionGroup.list)"
                :disabled="isDisabled(promotionGroup.list)"
                :margin-r="isNewCartrowVision ? 16/75+'rem' : 20/75+'rem'"
                @change="handleGroupCheckedItem(promotionGroup)"
              />
            </cart-item-header-promotion>
            <template v-else-if="!isPlatForm && !cartShowStore">
              <cart-item-header-full-gift
                v-show="!batchActive"
                v-if="fullGiftPromotion(mallCartItem.mall_code) && promotionGroup.type_id == 4"
                :key="mallCartItem.mall_code + '-'+promotionGroup.promotion_id"
                :integrity-promotion-info="fullGiftPromotion(mallCartItem.mall_code)"
                :show-condition="'picked'"
              />
              <cart-item-header-add-on
                v-show="!batchActive"
                v-if="fullAddOnPromotion(mallCartItem.mall_code) && promotionGroup.type_id == 22"
                :key="mallCartItem.mall_code + '-'+promotionGroup.promotion_id"
                :integrity-promotion-info="fullAddOnPromotion(mallCartItem.mall_code)"
                :show-condition="'picked'"
              />
            </template>
            <!-- SSR 直出6个商品 -->
            <template
              v-for="(item) in promotionGroup.list.filter(i => cartsId.indexOf(i.id) < ssrRenderNum)"
            >
              <component 
                :is="cartItemName"
                v-show="!doFilter + showFilterItem(item, filterType, targetCouponInfo, goodsFilterLabels)"
                class="j-valid-cart-item"
                :key="item.id"
                :item="item"
                :item-promotion-group-info="promotionGroup.group_type == 'store' ? emptyObject : promotionGroup"
                :is-s-s-r="isSSR"
                :isInlineStore="cartStoreInRow || (promotionGroup.subject == 1 && promotionGroup.group_type == 'promotion') || (cartShowStore && listItem.store_code === 'shein')"
              />
              <LowInventoryInfo
                v-if="isShowLowInventory(item)"
                :key="item.id+'inventory'"
                v-expose="{
                  id: '1-8-3-85'
                }"
                v-tap="{
                  id: '1-8-3-86'
                }"
                :img-url="lowinventoryImgUrl"
                :tip="lowinventoryTip"
                :locals="locals"
                @open="handleOpenOosDraw"
              />
            </template>
            <template
              v-for="(item) in promotionGroup.list.filter(i => cartsId.indexOf(i.id) >= onlySsrRenderNum && renderCount > cartsId.indexOf(i.id))"
            >
              <ClientOnly :key="promotionGroup.promotion_id+item.id">
                <component
                  :is="cartItemName"
                  v-show="!doFilter + showFilterItem(item, filterType, targetCouponInfo, goodsFilterLabels)"
                  class="j-valid-cart-item"
                  :item="item"
                  :item-promotion-group-info="promotionGroup.group_type=='store' ? emptyObject : promotionGroup"
                  :is-s-s-r="isSSR"
                  :isInlineStore="cartStoreInRow || (promotionGroup.subject == 1 && promotionGroup.group_type == 'promotion') || (cartShowStore && listItem.store_code === 'shein')"
                />
                <LowInventoryInfo
                  v-if="isShowLowInventory(item)"
                  v-expose="{
                    id: '1-8-3-85'
                  }"
                  v-tap="{
                    id: '1-8-3-86'
                  }"
                  :img-url="lowinventoryImgUrl"
                  :tip="lowinventoryTip"
                  :locals="locals"
                  @open="handleOpenOosDraw"
                />
              </ClientOnly>
            </template>
          </template>
        </template>
        <div 
          v-show="!batchActive && mallNegativeInformation(mallCartItem.mall_code)"
          v-tap="analysisData('1-8-3-19', mallCartItem.mall_code)"
          v-expose="analysisData('1-8-3-20', mallCartItem.mall_code)"
          class="negative-information-enter"
          :class="{'multi-mall': isPlatForm}"
          @click="showNegativeInformation(mallCartItem.mall_code)"
        >
          <i
            class="sui_icon_info_12px_2"
            :class="[iconPrefix]"
            style="font-size: 15px;"
          ></i>
          <span class="information-enter-tip">{{ language.SHEIN_KEY_PWA_23777 }}</span>
          <span class="information-enter-action">
            {{ language.SHEIN_KEY_PWA_23778 }}
          </span>
        </div>
      </div>
    </template>
    <div
      v-if="soldoutItems.length"
      v-show="!doFilter"
      v-expose="exposeSoldOuts()"
      class="warehouse-group"
      :class="[isPlatForm? 'platform-warehouse-group':'noplatform-warehouse-group']"
    >
      <save-all-group />
      <!-- SSR 直出5个商品 -->
      <component
        :is="cartItemName"
        v-for="(item) in soldoutItems.filter(i => cartsId.indexOf(i.id) < ssrRenderNum)"
        :key="item.id"
        :item="item"
        :index="cartsId.indexOf(item.id)"
        :is-s-s-r="isSSR"
      />
      <ClientOnly>
        <component
          :is="cartItemName"
          v-for="(item) in soldoutItems.filter(i => cartsId.indexOf(i.id) >= onlySsrRenderNum && renderCount > cartsId.indexOf(i.id))"
          :key="item.id"
          :item="item"
          :index="cartsId.indexOf(item.id)"
          :is-s-s-r="isSSR"
        />
      </ClientOnly>
    </div>
    <!-- 收藏动画 -->
    <img
      v-show="saveItem.show"
      :src="saveItem.img"
      :class="['add-completed-img', localLang == 'ar'|| localLang == 'il' || localLang == 'maar' ? 'add-completed__img-reverse' : '', {'add-completed-keyframes': saveItem.show}]"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import platformWarehouseHeader from '../platform/platformWarehouseHeader.vue'
import platformStoreHeader from '../platform/platformStoreHeader.vue'
import CartItemHeaderPromotion from './CartItemHeaderPromotion.vue'
// import NewCartItem from './newCartItem/index.vue'
import NewCartItem from './item/index.vue'
import CartItemHeaderAddOn from './CartItemHeaderAddOn.vue'
import CartItemHeaderFullGift from './CartItemHeaderFullGift.vue'
import saveAllGroup from './../CartSaveAll.vue'
import CartListFilter from './CartListFilter.vue'
import CartFilterCoupon from './CartFilterCoupon.vue'
import CartFilterVoucherTips from './CartFilterVoucherTips.vue'
import CartFilterGoodsTips from './CartFilterGoodsTips.vue'
import EmbedLoading from '@/public/src/pages/components/EmbedLoading.vue'
import LowInventoryInfo from './item/LowInventoryInfo.vue'
import cartMixin from '../../mixins/cart-mixin'
import { isValidCartItem } from '../../utils/index'
import { daEventCenter } from '../../../../services/eventCenter'
import { showFilterItem, showFilterHeader } from '../../utils/cartFilterLabels.js'
import { transfromAndCutImg } from 'public/src/services/resource/index'
import { getCouponListForCart, geCartCouponList } from 'public/src/pages/cartNew/utils/fetcher.js'
import { scrollToCartItem } from 'public/src/pages/cartNew/utils/index.js'
import { web_modify_check_click, web_modify_check_response } from '../../utils/metricTagsConfig.js'
import CheckedTip from './CheckedTip.vue'
import { Toast } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'
import { INCENTIVE_TYPES, POPOVER_CONFIGS } from '../cartCheckoutIncentive/incentiveTools.js'

export default {
  name: 'CartList',
  components: {
    platformWarehouseHeader,
    platformStoreHeader,
    CartItemHeaderPromotion,
    CartItemHeaderAddOn,
    CartItemHeaderFullGift,
    saveAllGroup,
    NewCartItem,
    CartListFilter,
    CartFilterCoupon,
    EmbedLoading,
    CartFilterVoucherTips,
    CartFilterGoodsTips,
    LowInventoryInfo,
  },
  mixins: [cartMixin],
  props: {
    isSSR: {
      type: Boolean,
      default: false
    },
    eventExpose: {
      type: Object,
      default: () => null
    },
    renderAll: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      embedLoadingShow: false,
      groupCheckTimeout: null,
      initRenderCount: 0,
      renderCount: 0,
      onlySsrRenderNum: 6,
      isResetStepper: false,
      isShowFilterTips: false, // show only once
      showNumber: false, // 是否展示筛选后的商品行数量
      showGoodsTip: false, // 是否展示筛选承接信息
    }
  },
  provide() {
    return {
      cutImg: this.cutImg
    }
  },
  computed: {
    ...mapState('cart', ['ssrPageType', 'locals', 'language', 'isPlatForm', 'mallCartList', 'soldoutItems', 'cartsId', 'integrityPromotionInfo', 'soldoutIds', 'batchActive', 'saveItem', 'batchEditItemIds', 'lastBatchEditItem', 'goodsFilterLabels', 'filterType', 'cartAbtInfo', 'isCheckedByLocal', 'blackFridayImgGoods', 'couponFilterLabelData', 'targetCouponInfo', 'couponInfo', 
      'showIncentiveProductListDialog', 
      'useBffApi',
      'lowinventoryItemInfo',
      'cartItemMap',
      'incentivePopoverContent',
      'isShowCartFloorDrawer',
      'incentiveItemIds',
      'incentivePopoverSwitchTimer',
      'filterHighWeightGoods'
    ]),
    ...mapGetters('cart', ['negativeInformation', 'iconPrefix', 'cartList', 'doFilter',
      'cartShowStore',
      'cartStoreInRow',
      'isNewCartrowVision',
      'cartfloorInform',
      'customServiceImgDomains',
      'lowinventory',
      'thirdPartyServiceImgDomains'
    ]),
    ssrRenderNum() {
      if(this.ssrPageType == 'cart') return this.onlySsrRenderNum
      return Math.min(this.onlySsrRenderNum, this.renderCount)
    },
    batchEditCartItemIds: {
      get () {
        return this.batchEditItemIds
      },
      set (arr) {
        this.updateState({
          key: 'batchEditItemIds',
          value: arr || []
        })
      }
    },
    cartItemName(){
      return 'NewCartItem'
    },
    infiniteScrollDistance() {
      return 10000
    },
    noMoreData() {
      return this.renderCount && this.renderCount >= this.cartsId.length
    },
    behaviorShow() {
      let abtStrValue = this.cartAbtInfo?.UserBehaviorTips?.param?.CartBehaviorShow
      if (!abtStrValue || abtStrValue == 'Hide') return false
      return true
    },
    lowinventoryImgUrl() {
      return getMainImageUrl({
        item: this.cartItemMap[this.lowinventoryItemInfo?.imgItemId] || {},
        IS_RW: false, 
        customServiceImgDomains: this.customServiceImgDomains,
        thirdPartyServiceImgDomains: this.thirdPartyServiceImgDomains
      })
    },
    lowinventoryTip() {
      return template(this.lowinventoryItemInfo?.itemIds?.length - 1, this.language.SHEIN_KEY_PWA_33615)
    },
  },
  watch: {
    noMoreData: {
      handler(v){
        if(typeof window == 'undefined') return
        if(v){
          this.$emit('noMoreItemRender')
        }
      },
      immediate: true
    },
    blackFridayImgGoods(goods) {
      const hasLabel = Object.keys(goods).some(key => !!goods[key]?.showBehaviorLabel?.length)
      daEventCenter.triggerNotice({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'userbehavior_tips',
          type: hasLabel ? this.behaviorShow ? 3 : 4 : this.behaviorShow ? 1 : 2
        }
      })
    },
    renderAll(o) {
      if(o) {
        this.renderCount = this.cartsId.length
      }
    }
  },
  created() {
    this.emptyObject = {}
  },
  activated() {
    this.initRenderCount = this.getRenderCountByCartId(this.$route.query.to)
    this.renderCount = Math.max(this.renderCount, this.initRenderCount)
    this.scrollToItemOnQuery()
  },
  mounted(){
    this.$emit('cartListIsMounted')
    this.initRenderCount = this.getRenderCountByCartId(this.$route.query.to)
    if(this.ssrPageType == 'cart') {
      this.renderCount = this.initRenderCount
      this.$nextTick(() => {
        this.$emit('renderRecommend')
      })
    } else {
      this.setListItem()
    }
    this.scrollToItemOnQuery()
    if (typeof window !== 'undefined') {
      let voucherFilterTipShowed = Boolean(localStorage.getItem('voucherFilterTipShowed'))
      if (voucherFilterTipShowed) { // show only once
        this.isShowFilterTips = false
      } else { // show only once
        this.isShowFilterTips = true
      }
    }
  },
  methods: {
    ...mapActions('cart', ['fetchModifyCartCheckStatus', 'handleFlashSaleOverScene', 'fetchCarts', 'handleCartFilterLabels', 'clearIncentivePopover']),
    ...mapMutations('cart', ['updateState', 'updateLastBatchEditItem']),
    ...mapMutations(['changeRootSLoadingStatus']),
    getRenderCountByCartId(id) {
      const toIndex = this.cartsId.indexOf(id) + 1
      const renderCount = Math.max(this.onlySsrRenderNum, toIndex)
      return renderCount
    },
    scrollToItemOnQuery() {
      const query = this.$route.query
      if (query.from === 'cart_floor' && query.to && String(query.to) !== '0') {
        const scroll = () => {
          this.$nextTick(() => {
            setTimeout(() => scrollToCartItem({ id: query.to, smooth: true, timeout: 500, highlightAnimate: true }), 0)
          })
          this.$router.replace({
            ...this.$route,
            query: {},
          })
        }
        if (this.cartfloorInform) {
          this.updateState({
            key: 'showCartFloorDrawer',
            value: true
          })
          this.$watch('notShowCartFloorDrawer', (n) => {
            if (n) {
              scroll()
            }
          }, {
            immediate: true
          })
          return
        }
        scroll()
      }
    },
    onTouchStart() {
      this.isResetStepper = false
    },
    onTouchMove() {
      if (!this.isResetStepper && !this.batchActive) {
        this.isResetStepper = true
      }
    },
    isCheckedWarehouse(mallCode){
      let mallItemCartList = this.cartList.filter(item => item.mall_code == mallCode && !this.soldoutIds.includes(item.id))
      return this.isChecked(mallItemCartList)
    },
    isDisabledWarehouse(mallCode) {
      return !this.cartList.filter(item => item.mall_code == mallCode && !this.soldoutIds.includes(item.id)).length
    },
    isDisabled(goodsList = []) {
      return !goodsList.filter(item => !this.soldoutIds.includes(item.id)).length
    },
    isChecked (goodsList = []) {
      if(this.batchActive){
        return goodsList.filter(item => !this.batchEditCartItemIds.includes(item.id)).length ? false : true
      }
      if (this.isDisabled(goodsList)) return false

      // 优先使用存在本地缓存的选中状态
      let target = goodsList.filter(f => typeof this.isCheckedByLocal[f.id] === 'number' ? this.isCheckedByLocal[f.id] === 0 : f.is_checked == 0 && !this.soldoutIds.includes(f.id))
      // 全是勾选的商品
      if (!target.length) return true

      return false
    },
    handleBatchActiveCheckedItem ( { value = '', checked = false }){
      // console.log('handleBatchActiveCheckedItem', value, checked)
      if (this.lastBatchEditItem[value] && checked) { // checkbox会触发两次change事件
        this.updateState('lastBatchEditItem', {})
        return
      }
      this.lastBatchEditItem[value] = checked
      daEventCenter.triggerNotice({
        daId: '1-8-7-28',
        extraData: {
          checked: checked,
        }
      })
    },
    handleClickItem({ item, CartItemEditor }) {
      if (item.isMallSwitchGoods || item.isSaleAttrSwitchGoods) {
        const btnText = item.isMallSwitchGoods ? CartItemEditor.switchToMallText :  this.language.SHEIN_KEY_PWA_16112
        const content = item.isMallSwitchGoods ? this.language.SHEIN_KEY_PWA_33153 : this.language.SHEIN_KEY_PWA_33154
        daEventCenter.triggerNotice({
          daId: '1-8-3-79',
          extraData: {
            type: item.isMallSwitchGoods ? 1 : 2,
          }
        })
        const toast = Toast({
          duration: 5000,
          content: this.$createElement(CheckedTip, {
            props: {
              content,
              btnText,
            },
            on: {
              close: () => {
                toast.close()
              },
              click: () => {
                daEventCenter.triggerNotice({
                  daId: '1-8-3-80',
                  extraData: {
                    type: item.isMallSwitchGoods ? 1 : 2,
                  }
                })
                if (item.isMallSwitchGoods) {
                  CartItemEditor.handleSwitchMall()
                } else if (item.isSaleAttrSwitchGoods) {
                  CartItemEditor.handleEdit()
                }
              }
            }
          }),
        })
      }
    },
    // CartItemCheckbox 组件触发
    handleCheckedItem ( { value = '' }){
      let targetItem = this.cartList.find(item => item.id == value)
      // 优先使用存在本地缓存的选中状态
      const checkedStatus = typeof this.isCheckedByLocal[value] === 'number' ? this.isCheckedByLocal[value] : targetItem.is_checked
      web_modify_check_click(checkedStatus ? 0 : 1, 'item')
      if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
        this.$toast(this.language.SHEIN_KEY_PWA_20624)
        return
      }
      let reqParam = {
        operation_type: checkedStatus ? 2 : 1,
        cart_id_list: [],
        editCartId: value
      }
      reqParam.cart_id_list.push(value)
      if(!checkedStatus){
        reqParam.cart_id_list = reqParam.cart_id_list.concat(targetItem.appendIds || [])
      }
      let scene = 'cart'
      if (this.showIncentiveProductListDialog) {
        scene = this.incentivePopoverContent?.type === 'credit_reward' ? 'clubpop' : 'benefit_pop'
      }
      if (this.isShowCartFloorDrawer) {
        scene = 'cart_floor_benefit_pop'
      }
      daEventCenter.triggerNotice({
        daId: '1-8-3-7',
        extraData: {
          select_type: 1,
          is_select: checkedStatus,
          goods_sn: targetItem.product.goods_sn,
          position: 'page',
          scene,
          // 勾选商品时在哪个筛选标签下。注意券需要上报couponType
          label_id: !isNaN(this.filterType)
            ? this.goodsFilterLabels.find(i => this.filterType == i.id)?.couponType
            : this.filterType
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((code = '') => {
        web_modify_check_response(checkedStatus ? 0 : 1, 'item', code == 0 ? 1 : 0)
      })
    },
    handleGroupCheckedItem (group){
      if(this.groupCheckTimeout) return
      this.groupCheckTimeout = setTimeout(()=>{
        let groupCartList = group?.list || []
        if(this.batchActive){
          let isChecked = this.isChecked(groupCartList)
          groupCartList.forEach(item => {
            if(!isChecked){
              if(!this.batchEditCartItemIds.includes(item.id)){
                this.batchEditCartItemIds.push(item.id)
              }
            } else {
              let site = this.batchEditCartItemIds.indexOf(item.id)
              site > -1 && (this.batchEditCartItemIds.splice(site, 1))
            }
          })
          this.groupCheckTimeout = null
        } else {
          let isChecked = this.isChecked(groupCartList)
          web_modify_check_click(isChecked ? 0 : 1, 'section')
          let reqParam = {
            operation_type: isChecked ? 2 : 1,
            cart_id_list: []
          }
          reqParam.cart_id_list = groupCartList.map(item => item.id)
          if(!isChecked){
            let giftIds = this.findGiftOrAddonCartItemId(groupCartList)
            reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
          }
          this.groupCheckTimeout = null
          daEventCenter.triggerNotice({
            daId: '1-8-3-7',
            extraData: {
              select_type: 2,
              is_select: isChecked ? 1 : 0,
              goods_sn: groupCartList.map(item => item.product.goods_sn).join(),
              position: 'page',
            }
          })

          this.fetchModifyCartCheckStatus(reqParam).then((code = '') => {
            web_modify_check_response(isChecked ? 0 : 1, 'section', code == 0 ? 1 : 0)
          })
        }
      }, 0)
    },
    handleStoreCheckedItem ( { value = '' }){
      let [mall_code = '', store_code = ''] = value.split(',')
      let mallItem = this.mallCartList.find(mall => mall.mall_code == mall_code)
      let storeCartList = mallItem && mallItem.mall_list.find(item => item.store_code == store_code)?.store_list_origin || []
      if(this.batchActive) {
        let isChecked = this.isChecked(storeCartList)
        storeCartList.forEach(item => {
          if(!isChecked) {
            if(!this.batchEditCartItemIds.includes(item.id)) {
              this.batchEditCartItemIds.push(item.id)
            }
          } else {
            let index = this.batchEditCartItemIds.indexOf(item.id)
            index > -1 && (this.batchEditCartItemIds.splice(index, 1))
          }
        })
      } else {
        let isChecked = this.isChecked(storeCartList)
        web_modify_check_click(isChecked ? 0 : 1, 'section')
        let reqParam = {
          operation_type: isChecked ? 2 : 1,
          cart_id_list: []
        }
        reqParam.cart_id_list = storeCartList.map(item => item.id)
        if(!isChecked){
          let giftIds = this.findGiftOrAddonCartItemId(storeCartList)
          reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
        }
        daEventCenter.triggerNotice({
          daId: '1-8-3-7',
          extraData: {
            select_type: 2,
            is_select: isChecked ? 1 : 0,
            goods_sn: storeCartList.map(item => item.product.goods_sn).join(),
            position: 'page',
          }
        })
        this.fetchModifyCartCheckStatus(reqParam).then((code = '') => {
          web_modify_check_response(isChecked ? 0 : 1, 'section', code == 0 ? 1 : 0)
        })
      }
    },
    handleWarehouseCheckedItem ( { value = '' }){
      let cartList = this.cartList.filter(item => item.mall_code == value && !this.soldoutIds.includes(item.id))
      let isChecked = this.isChecked(cartList)
      web_modify_check_click(isChecked ? 0 : 1, 'section')
      let reqParam = {
        operation_type: isChecked ? 2 : 1,
        cart_id_list: []
      }

      reqParam.cart_id_list = cartList.map(item => item.id)
      if(!isChecked){
        let giftIds = this.findGiftOrAddonCartItemId(cartList)
        reqParam.cart_id_list = reqParam.cart_id_list.concat(giftIds)
      }
      daEventCenter.triggerNotice({
        daId: '1-8-3-7',
        extraData: {
          select_type: 2,
          is_select: isChecked ? 1 : 0,
          goods_sn: cartList.map(item => item.product.goods_sn).join(),
          position: 'page',
        }
      })
      this.fetchModifyCartCheckStatus(reqParam).then((code = '') => {
        web_modify_check_response(isChecked ? 0 : 1, 'section', code == 0 ? 1 : 0)
      })
    },
    soldOutItemTip(){
      if(this.batchActive) return
      this.$toast(this.language.SHEIN_KEY_PWA_20679)
    },
    findGiftOrAddonCartItemId(groupCartList = []){
      let giftIds = []
      groupCartList.forEach(item => {
        giftIds = giftIds.concat(item.appendIds)
      })
      giftIds = [...new Set(giftIds)]
      return giftIds
    },
    fullGiftPromotion(mallCode) {
      return Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && item.mall_code == mallCode)
    },
    fullAddOnPromotion(mallCode){
      return Object.values(this.integrityPromotionInfo).find(item => [22].includes(+item.type_id) && item.mall_code == mallCode)
    },
    mallNegativeInformation (mall_code){
      return this.negativeInformation[mall_code]
    },
    showNegativeInformation(mall_code) {
      this.updateState({ key: 'negativeInformationMallCode', value: mall_code })
    },
    analysisData (id, mallCode) {
      return {
        id: id,
        once: false,
        data: {
          mall_code: mallCode
        }
      }
    },
    exposeSoldOuts() {
      const goodsInfo = []
      this.soldoutItems.forEach((item) => {
        let saleStatus = ''
        if (isValidCartItem(item, this.isPlatform)) {
          saleStatus = 0
        } else {
          saleStatus = +item.quantity > +item.realTimeInventory ? 2 : 1
        }

        let member_gift_info = '-'
        if (item.isSheinClubGiftItem) {
          member_gift_info = item.isInvalid ? 'invalid' : 'no_stock'
        }

        goodsInfo.push(`${item.product.goods_id}\`${saleStatus}\`${member_gift_info}`)
      })
      return {
        id: '1-8-3-28',
        data: {
          goods_info: goodsInfo.join(','),
        }
      }
    },
    async handleFilterClick(res) {
      if(typeof window !== 'undefined' && !navigator.onLine) {
        this.$toast(this.language.SHEIN_KEY_PWA_31762)
        return
      }
      // 选中的筛选标签是否为券
      const isCoupon = !isNaN(res)
      this.embedLoadingShow = true
      this.showNumber = false
      this.showGoodsTip = false
      // 筛选状态更新后页面回到顶部重新刷新数据
      if (window.scrollY) {
        window.scrollTo({ top: 0 })
      }
  
      let targetCoupon = {}
      try {
        if (isCoupon) {
          // 如果选中的是券，同时调券助手接口获取最新的券数据并保存当前选中的券数据
          const promiseList = [this.fetchCarts()]
          if (this.useBffApi.cart_v4) {
            promiseList.push(geCartCouponList({ enableCouponCmp: 0 }))
          } else {
            promiseList.push(getCouponListForCart())
          }
          const promiseRes = await Promise.all(promiseList)
          const couponInfoRes = promiseRes[1]
          const couponId = res
          if (couponInfoRes.code == 0) {
            const { disabledCouponList, usableCouponList } = couponInfoRes?.info
            const coupon = [...disabledCouponList, ...usableCouponList]
            targetCoupon = coupon?.find(i => i.id == couponId)
            if (targetCoupon?.id) {
              targetCoupon['couponType'] = targetCoupon.coupon_type_id == '1' 
                ? `coupon_money_${targetCoupon.coupon}`
                : `coupon_off_${targetCoupon.coupon}`   // 用于埋点
            } else {
              // 如果targetCoupon为空，说明这张券已经失效，需要重新更新登陆的券标签
              // console.log('--这张券已经失效', couponId)
              this.$toast(this.language.SHEIN_KEY_PWA_24107)
              this.updateState({ key: 'couponFilterLabelData', value: res?.info || {} })
              this.handleCartFilterLabels()
              this.updateState({ key: 'filterType', value: 'none' })
              this.updateState({ key: 'targetCouponInfo', value: {} })
              this.updateState(({ key: 'filterHighWeightGoods', value: {} }))
              return
            }
          }
        } else {
          await this.fetchCarts()
        }
      } catch (error) {
        console.error('fetchCarts', error)
      }
      // console.log('--targetCoupon', targetCoupon)
      this.renderCount = this.cartsId.length
      if(!window.scrollY){
        window.scrollTo(0, 1)
      }
      let curSelectedLabel, targetLabel
      if (this.filterType != 'none') {
        curSelectedLabel = this.goodsFilterLabels.find(i => i.type == 'coupon'
          ? i.id == this.filterType 
          : i.type == this.filterType
        )
      }
      if (res != 'none') {
        targetLabel = this.goodsFilterLabels.find(i =>
          i.type == 'coupon' ? i.id == res : i.type == res
        )
      }
      if(res == 'none') {
        if(this.filterType != 'none' && !curSelectedLabel) {
          this.$toast(this.language.SHEIN_KEY_PWA_24107)
        }

        this.updateState({ key: 'filterType', value: res })
        this.updateState({ key: 'targetCouponInfo', value: targetCoupon })
        this.updateState(({ key: 'filterHighWeightGoods', value: {} }))
      } else {
        if(!targetLabel) {
          this.$toast(this.language.SHEIN_KEY_PWA_24107)
          if(this.filterType != 'none' && !curSelectedLabel) {
            this.updateState({ key: 'filterType', value: 'none' })
            this.updateState({ key: 'targetCouponInfo', value: {} })
            this.updateState(({ key: 'filterHighWeightGoods', value: {} }))
          }
        } else {
          if(this.filterType != 'none' && !curSelectedLabel) {
            this.$toast(this.language.SHEIN_KEY_PWA_24107)
          }
          this.updateState({ key: 'filterType', value: res })
          this.updateState({ key: 'targetCouponInfo', value: targetCoupon })
        }
      }
      // this.changeRootSLoadingStatus({ show: false })
      // 券筛选情况下，更新targetCouponInfo后，会显示券信息模块，需要重置滚动状态
      if (window.scrollY) {
        window.scrollTo({ top: 0 })
      }
      this.embedLoadingShow = false
      this.showNumber = true
      if(typeof window !== 'undefined') {
        // 保存筛选状态, 用于下单页 click_place_order 埋点
        sessionStorage.setItem('filterType', this.filterType)
      }
    },
    /** 图片裁切 */
    cutImg (imgUrl, designWidth, exp) {
      const { RESOURCE_SDK = {} } = this.locals || {}
      const { deviceData = '', isSupportWeb = '', isSupprotCut = false, sceneMap = {} } = RESOURCE_SDK || {}

      const cutData = {
        deviceData,
        isSupportWebp: Boolean(isSupportWeb),
        isSupprotCut,
        imgUrl,
        designWidth: Number(designWidth),
        sceneMap,
        exp,
      }

      return transfromAndCutImg(cutData)
    },
    // doFilter为false时, 所有item都显示, 当其为true时, 再根据filterType, 使用以下两个方法筛选合适的item展示
    showFilterHeader,
    showFilterItem,
    infiniteScroll(){
      if(this.noMoreData || this.renderCount < 6) return
      this.renderCount = this.renderCount + 6
      if(this.eventExpose){
        this.$nextTick(() => {
          this.eventExpose.update('page-cart-expose-events')
        })
      }
    },
    // 分块渲染前6个商品，其余商品走滚动渲染
    setListItem() {
      if (this.renderCount >= this.initRenderCount || this.noMoreData) {
        this.$emit('renderRecommend')
        return
      }
      requestAnimationFrame(() => {
        this.renderCount++
        this.setListItem()
      })
    },
    isShowLowInventory(item) {
      return this.lowinventory && this.lowinventoryItemInfo.id == item.id && this.lowinventoryItemInfo?.itemIds?.length >= 2 && this.filterType == 'none' && !this.batchActive && this.cartsId?.length - this.soldoutIds?.length > 4
    },
    handleOpenOosDraw() {
      // 更新数据弹窗内数据, 缓存旧数据及定时器, 关闭时恢复旧数据和定时器
      // 在lowinventoryItemInfo中缓存数据 incentivePopoverContent, incentiveItemIds
      // 若当前利诱有值, 缓存当前利诱的值
      if(this.incentivePopoverContent?.type) {
        this.updateState({
          key: 'lowinventoryItemInfo',
          value: {
            ...(this.lowinventoryItemInfo || {}),
            incentivePopoverContent: { ...(this.incentivePopoverContent || {}) },
            incentiveItemIds: Array.from(this.incentiveItemIds),
          }
        })
      }
      this.updateState({
        key: 'incentivePopoverContent',
        value: {
          type: INCENTIVE_TYPES,
          ...POPOVER_CONFIGS['oos'],
          lowInventory: true,
        }
      })
      this.updateState({
        key: 'incentiveItemIds',
        value: this.lowinventoryItemInfo.itemIds
      })
      this.clearIncentivePopover() // 若气泡处于打开状态则, 关闭气泡, 并清除关闭气泡倒计时
      // 如果处于气泡切换状态, 则暂停气泡gap计时, 关闭弹窗后再重新计时
      if(this.incentivePopoverSwitchTimer) {
        clearTimeout(this.incentivePopoverKeepTimer)
        this.updateState({
          key: 'incentivePopoverKeepTimer',
          value: null
        })
      }
      this.updateState({
        key: 'showIncentiveProductListDialog',
        value: true,
      })
    },
    filterTipsClose() {
      this.isShowFilterTips = false
      localStorage.setItem('voucherFilterTipShowed', true) // 关闭后不再展示
    },
    updateGoodsTipShow(val) {
      this.showGoodsTip = val
    }
  }
}
</script>

<style lang="less">
.warehouse-group__opt-bg{
  position: relative;
  // background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAA0NDQ0ODQ4QEA4UFhMWFB4bGRkbHi0gIiAiIC1EKjIqKjIqRDxJOzc7STxsVUtLVWx9aWNpfZeHh5e+tb75+f8BDQ0NDQ4NDhAQDhQWExYUHhsZGRseLSAiICIgLUQqMioqMipEPEk7NztJPGxVS0tVbH1pY2l9l4eHl761vvn5///CABEIAJQBZgMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAAB//aAAgBAQAAAACkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAxAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//EABQQAQAAAAAAAAAAAAAAAAAAAJD/2gAIAQEAAT8AVf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAcP/aAAgBAgEBPwBm/8QAFBEBAAAAAAAAAAAAAAAAAAAAcP/aAAgBAwEBPwBm/9k=);
  .opt-bg-img{
    position: absolute;
    height: 4rem;
    width: 100%;
    z-index: -1;/* stylelint-disable-line declaration-property-value-blacklist */
  }
}
.warehouse-group{
  margin: 16/75rem 12/75rem 0;
  .full-type-group{
    margin: 0;
  }
  .checkbox-item{
    line-height: 3.41rem;
    display: flex;
    justify-content: center;
    &.new-checkbox-item{
      line-height: 148/75rem;
    }
  }
  .store-group-gap{
    margin-top: 4/75rem;
    padding: 0 0.32rem;
    height: 1px;
    background: #fff;
  }
}
.noplatform-warehouse-group{
  .cart-list-item + .cart_item-header, .cart-list-item + .store-header-container, .cart-list-item + .full-type-group{
    position: relative;
    margin-top: 16/75rem;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: none;
      top: 0;
    }
  }
  .full-type-group + .cart_item-header, .full-type-group + .store-header-container{
    position: relative;
    margin-top: 16/75rem;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: none;
      top: 0;
    }
  }
}
.noplatform-show-store{
  .cart-list-item + .cart_item-header{
    position: relative;
    margin-top: 0;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
    }
  }
}
.platform-warehouse-group{
  .cart-list-item + .cart_item-header{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100% - .533335rem");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
    }
  }
  .cart-list-item:last-child{
    background: transparent;
    >div{
      border-radius: 0 0 16/75rem 16/75rem;
    }
  }
  .cart-list-item + .store-header-container{
    border-top: .5px solid #E5E5E5;
  }
  .not-promotion-item  + .cart_item-header{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: calc(~"100%");
      border-bottom: .5px solid #E5E5E5;
      top: 0;
      left: 0;
    }
  }
}
.header-platform + .header-platform{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    width: calc(~"100%");
    border-bottom: .5px solid #E5E5E5;
    top: 0;
  }
}
.full-type-group{
  padding-bottom: 32/75rem;
  background: #fff;
  margin: 0 16/75rem;
  & +  .full-type-group{
    margin-top: 20/75rem;
  }
  &.header-platform{
    margin: 0;
    padding: 0;
    & +  .full-type-group{
      margin-top: 0;
    }
    .cart_item-header{
      margin: 0;
      padding: 0;
    }
    .cart_item-banner{
      padding: 20/75rem;
      border-bottom: 0.5px solid #E5E5E5;
    }
    .no-border{
      border-bottom: 0
    }
    .is-single-mall{
      padding: 20/75rem 24/75rem;
    }
  }
}
.cart_item-header{
  padding: 32/75rem 24/75rem 0 20/75rem;
  background-color: #FFFFFF;
  display: flex;
  &.new-promotion-header{
    background-color: @sui_color_promo_bg;
    padding: 20/75rem;
    &.new-cartrow-vision {
      padding-left: 16/75rem;
    }
    .cart_item-banner{
      padding: 0;
    }
  }
}
.cart-item__title-banner,.cart_item-banner{
  background-color: @sui_color_promo_bg;
  padding: 32/75rem 24/75rem;
  flex: 1;
  .cart_item-title{
    font-size: 14px;
    color: #222;
    font-weight: 700;
  }
  .cart_item-tip{
    color: #222;
    font-size: 12px;
    flex: 1;
    line-height: 1.2;
    &.cart_tip-red {
      color: #bf4123;
    }
    em {
      color: @sui_color_welfare_dark;
    }
  }
  .cart_item-desc{
    color: #222;
    font-size: 12px;
    line-height: 1.2;
    em{
      color: @sui_color_welfare_dark;
    }
  }
  .count-down-time{
    .time-content{
      font-weight: bold;
    }
  }
}
.title-action-operation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cart_item-action{
    font-weight: bold;
    font-size: 12px;
    white-space: nowrap;
    color: #222;
    display: inline-flex;
    align-items: center;
    i{
      font-weight: bold;
    }
  }
  .promotion-tip-time{
    flex: 1;
    text-align: left;
    .cart_item-desc,.down-time{
      padding: 0 20/75rem 0;
    }
  }
  .is-single-mall{
    .cart_item-desc,.down-time{
      padding: 0 24/75rem 0 16/75rem;
    }
  }
  .new-promotion-header__icon{
    margin-right: 8/75rem;
    width: 30/75rem;
    height: 30/75rem;
    line-height: 1;
    font-size: 15px!important; /* stylelint-disable-line declaration-no-important */
  }
  .promotion-header-content{
    flex: 1;
  }
}
.item-content-icon {
  width: 32/75rem;
  height: 32/75rem;
  line-height: 1;
}
.add-completed-img {
  width: 10rem;
  display: block;
  position: fixed;
  top: 0;
  opacity: .5;
  z-index: 10000; /* stylelint-disable-line declaration-property-value-blacklist */
  &.add-completed-keyframes {
    animation-name: add-completed-transform-keyframes;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: 0 0;
  }
}

@keyframes add-completed-transform-keyframes {
  0% {transform:translate(0px, 0px) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(8.6rem, .4rem) scale(0);}
}

.add-completed__img-reverse {
  &.add-completed-keyframes {
    animation-name: add-completed-transform-keyframes-reverse;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: 0 0;
  }
}
@keyframes add-completed-transform-keyframes-reverse {
  0% {transform:translate(0px, 0px) scale(1) ;animation-timing-function: cubic-bezier(.25,.25,.75,.75);}
  100% {transform:translate(1.3rem, .4rem) scale(0);}
}
ul, li{
  list-style: none;
}
.negative-information-enter{
  display: flex;
  align-items: center;
  padding: 20/75rem 8/75rem 20/75rem 0;
  font-size: 12px;
  color: @sui_color_gray_dark3;
  i{
      margin-right: 8/75rem;
      width: 30/75rem;
  }
  .information-enter-tip{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    padding-right: 4/75rem;
  }
  .information-enter-action{
    color: @sui_color_link;
  }
  &.multi-mall{
    background-color: #fff;
    border-top: 0.5px solid #e5e5e5;
    border-radius: 0 0 0.21333333rem 0.21333333rem;
    padding: 20/75rem;
  }
}
</style>
