var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-checkbox-tag',{staticClass:"filter-check-tag",class:{
    'style-a': _vm.abtStyle == 'A',
    'style-b': !_vm.inModal && _vm.abtStyle == 'B',
    'style-b-modal': _vm.inModal && _vm.abtStyle == 'B',
    'j_coupon-label': _vm.isCoupon(_vm.label),
    'scan-lights': _vm.isCoupon(_vm.label) && _vm.showScanLights
  },attrs:{"style-type":"white","hide-icon":true,"label":_vm.isCoupon(_vm.label) ? _vm.label.id : _vm.label.type,"cancel-mark":_vm.cancelMark}},[_c('div',{staticClass:"filter-label"},[(_vm.showPrefixIcon)?[(_vm.prefixIcon)?_c('Icon',{staticClass:"prefix-icon custom-prefix-icon",attrs:{"size":"12px","name":_vm.prefixIcon}}):_c('Icon',{staticClass:"prefix-icon",attrs:{"size":"12px","data-type":_vm.label.type,"is-responsive-name":true,"name":_vm.getIconName(_vm.label)}})]:_vm._e(),_vm._v(" "),(_vm.showDivider)?_c('i',{staticClass:"divider"}):_vm._e(),_vm._v(" "),_c('TransitionGroup',{staticClass:"transition-tag",attrs:{"name":"slide","tag":"div"}},_vm._l((_vm.slideItems),function(item){return _c('div',{key:item,staticClass:"label-tip"},[_vm._v("\n        "+_vm._s(item + _vm.totalText)+"\n      ")])}),0),_vm._v(" "),_c('div'),_vm._v(" "),(_vm.showSuffixIcon && _vm.showClose && _vm.checked)?_c('Icon',{staticClass:"suffix-icon",attrs:{"name":"sui_icon_close_16px","size":"14px"}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }