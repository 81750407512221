<template>
  <div
    v-if="showPromotion"
    class="j-full-gift-header full-type-group header-platform"
    :class="{
      'j-top-full-gift': isSingleMall && !showAddFullGift && !item,
      'newcomer-guide__fullgifts': showFullGiftsList
    }"
  >
    <div
      class="cart_item-header item-header__full-gift ele-wrapper"
    >
      <section
        class="cart_item-banner"
        tabindex="0"
        :class="{'no-border': showCondition == 'picked' || showFullGiftsList, 'is-single-mall': isSingleMall}"
      >
        <div class="title-action-operation">
          <i
            class="promotion-item-icon item-content-icon"
          >
            <sui_icon_gift_18px size="18px" />
          </i>
          <div
            class="promotion-tip-time"
            :class="{'is-single-mall': isSingleMall}"
          >
            <!-- 提示 -->
            <p
              class="cart_item-desc"
              tabindex="0"
              v-html="fullGiftTip"
            >
            </p>
            <!-- 倒计时 -->
            <div
              v-if="countDown.show"
              class="client-only-height down-time"
            >
              <ClientOnly>
                <count-down-time :time-stamp="countDown.timeStamp" />
              </ClientOnly>
            </div>
          </div>
          <!-- 点击按钮 -->
          <div
            v-enterkey
            class="cart_item-action"
            tabindex="0"
            role="button"
            @click="handleFullGift"
          >
            <span>{{ actionText }}</span>
            <sui_icon_more_right_12px
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <!--   吸顶元素高度占位     -->
    <div class="j-top-act-height"></div>
    <full-gifts-list
      v-if="showFullGiftsList"
      :gift-list="getGiftLift"
      :promotion-id="fullGiftId"
      :disabled-tip="promotionContent.action !=2 ? fullGiftTip : ''"
      :promotion-type="promotionType"
      :is-top="true"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ClientOnly from 'vue-client-only'
import fullGiftsList from './fullGiftsList.vue'
import { isObject } from '@shein/common-function'
import { sui_icon_more_right_12px, sui_icon_gift_18px } from '@shein-aidc/icon-vue2'
import dayjs from 'dayjs'

export default {
  name: 'FullGiftTop',
  components: {
    ClientOnly,
    fullGiftsList,
    sui_icon_more_right_12px,
    sui_icon_gift_18px
  },
  props: {
    // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    },
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showCondition: {
      type: String,
      default: '' // 单mall：notPick picked
    },
  },
  computed: {
    ...mapState('cart', ['language', 'cartAbtInfo', 'soldoutIds', 'locals', 'cartInfo']),
    ...mapGetters('cart', ['cartPromotionMap', 'iconPrefix', 'cartList']),
    showPromotion(){
      return this.showCondition ? ((this.showCondition == 'notPick' && !this.item) || (this.showCondition == 'picked' && this.item)) && this.fullGiftTip : this.fullGiftTip
    },
    showFullGiftsList(){
      return !this.item && this.getGiftLift.length
    },
    promotionContent() {
      return this.integrityPromotionInfo.content || {}
    },
    item() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == this.integrityPromotionInfo.promotion_id))
      return isPicked
    },
    // 选择赠品是否有效
    isChecked() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == this.integrityPromotionInfo.promotion_id && item.is_checked))
      return isPicked
    },
    fullGiftTip(){
      const tip = this.promotionContent.tip
      if (isObject(tip)) {
        if (this.isSheinClubPromotion) return tip[this.item ? 1 : 0]
        return tip[this.isChecked ? 1 : 0]
      }
      return tip
    },
    showAddFullGift(){
      return this.promotionContent.action == 1
    },
    // 使用的全场活动id
    fullGiftId () {
      return this.integrityPromotionInfo.promotion_id
    },
    isSheinClubPromotion() {
      return [8, 14].includes(this.integrityPromotionInfo.promotion_logo_type)
    },
    countDown() {
      if (this.isClubGiftPromotion && this.integrityPromotionInfo.promotion_logo_type === 8) {
        const target = this.cartInfo.promotion_member_info?.find?.(f => f.promotion_id === this.fullGiftId)
        const timeStamp = target?.member_equity_end_time ?? ''
        const lessThan72 = timeStamp && dayjs(timeStamp * 1000).diff(dayjs(), 'hour') < 72
        return {
          show: !!target && lessThan72,
          timeStamp,
        }
      }
      return {
        show: this.integrityPromotionInfo.is_count_down == 1,
        timeStamp: this.integrityPromotionInfo.end_time,
      }
    },
    getGiftLift(){
      return this.promotionContent.showFullGiftList || []
    },
    // 不满足门槛或者满足门槛已选赠品时不需要吸顶
    notNeedSticky(){
      return this.showAddFullGift || (this.promotionContent.action == 2 && this.item)
    },
    promotionType() {
      return this.promotionContent.typeId
    },
    actionText() {
      if (!this.showAddFullGift) {
        return this.item ? this.language.SHEIN_KEY_PWA_15831 : this.language.SHEIN_KEY_PWA_15830
      }

      return this.language.SHEIN_KEY_PWA_15091
    }
  },
  watch: {
    notNeedSticky(v){
      if(v){
        if($('.j-full-gift-header .ele-wrapper').hasClass('sticky-block')){
          $('.j-full-gift-header .ele-wrapper').removeClass('sticky-block').css({ top: 0 } )
          $('.j-full-gift-header .j-top-act-height').css('height', 0)
        }
      }
    }
  },
  mounted () {
    daEventCenter.triggerNotice({
      daId: '1-8-5-1',
      extraData: {
        id: this.fullGiftId,
        typeId: 4,
        isFullPromotion: this.cartPromotionMap[this.fullGiftId],
        is_member_gift: +this.isSheinClubPromotion,
      }
    })
  },
  methods: {
    ...mapMutations('cart', ['changeAppendageDrawerStatus', 'updateState']),
    handleFullGift () {
      if($('.j-top-full-gift .ele-wrapper').hasClass('sticky-block') && this.showFullGiftsList){
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return
      }
      const promotionId = this.fullGiftId
      if(!this.showAddFullGift) {
        this.changeAppendageDrawerStatus({ status: this.integrityPromotionInfo.promotion_logo_type === 8 ? 'clubGiftShow' : 'fullGiftShow', promotionId: promotionId })
      } else {
        this.handleAddOn({ promotion_id: promotionId })
      }
      
      daEventCenter.triggerNotice({
        daId: '1-8-5-2',
        extraData: {
          id: promotionId,
          typeId: 4,
          isFullPromotion: this.cartPromotionMap[this.fullGiftId],
          is_member_gift: +this.isSheinClubPromotion,
        }
      })
    },
    handleAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.item-header__full-gift{
  padding: 32/75rem 24/75rem 0;
  margin: 20/75rem 0 0;
  &.sticky-block{
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
  }
}
</style>
