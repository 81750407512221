<template>
  <div class="warehouse-container">
    <div class="warehouse-content">
      <slot></slot>
      <div class="warehouse-title">
        <span class="mall-name">{{ mallInfo.mall_name }}</span>
        <s-label
          v-if="isLocalShipment"
          type="success"
          style="font-weight: normal"
        >
          {{ language.SHEIN_KEY_PWA_20949 }}
        </s-label>
      </div>
    </div>
    <platform-shipping :mall-code="mallCode" />
    <full-court-map
      :mall-code="mallCode"
      :is-single-mall="false"
    />
    <template v-if="isPlatForm">
      <!-- 全场满赠(付费会员)(有门槛) -->
      <full-gift-promotion-top
        v-if="sheinClubThresholdFullGiftPromotion"
        :integrity-promotion-info="sheinClubThresholdFullGiftPromotion"
        :is-single-mall="false"
      />
      <!-- 全场满赠(付费会员)(无门槛) -->
      <full-gift-promotion-top
        v-if="sheinClubFullGiftPromotion"
        :integrity-promotion-info="sheinClubFullGiftPromotion"
        :is-single-mall="false"
      />
      <!-- 全场满赠 -->
      <full-gift-promotion-top
        v-if="fullGiftPromotion"
        :integrity-promotion-info="fullGiftPromotion"
        :is-single-mall="false"
      />
      <!-- 全场加价购 -->
      <add-on-promotion-top
        v-if="fullAddOnPromotion"
        :integrity-promotion-info="fullAddOnPromotion"
        :is-single-mall="false"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Label } from '@shein/sui-mobile'
import platformShipping from './platformShipping.vue'
import fullCourtMap from '../promotions/fullCourtMap.vue'
import addOnPromotionTop from '../list/CartItemHeaderAddOn'
import fullGiftPromotionTop from '../list/CartItemHeaderFullGift'
import Vue from 'vue'
Vue.use(Label)

export default {
  name: 'PlatformWarehouseHeader',
  components: {
    platformShipping,
    fullCourtMap,
    fullGiftPromotionTop,
    addOnPromotionTop
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('cart', ['language', 'isPlatForm', 'mallList', 'integrityPromotionInfo']),
    ...mapGetters(['isSheinClubMember']),
    mallInfo(){
      return this.mallList.find(item => item.mall_code == this.mallCode) || {}
    },
    sheinClubThresholdFullGiftPromotion() {
      return this.isSheinClubMember && Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && +item.promotion_logo_type === 14 && item.mall_code == this.mallCode)
    },
    sheinClubFullGiftPromotion() {
      return this.isSheinClubMember && Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && +item.promotion_logo_type === 8 && item.mall_code == this.mallCode)
    },
    fullGiftPromotion() {
      return Object.values(this.integrityPromotionInfo).find(item => [4].includes(+item.type_id) && ![8, 14].includes(+item.promotion_logo_type) && item.mall_code == this.mallCode)
    },
    fullAddOnPromotion(){
      return Object.values(this.integrityPromotionInfo).find(item => [22].includes(+item.type_id) && item.mall_code == this.mallCode)
    },
    // mall标签 1：兜底、2：平台、3：自营、4：跨境、5：本土，逗号分隔
    // 有“本土”标签，则展示Local Shipment标签
    isLocalShipment(){
      return this.mallInfo.mall_tag?.split(',').includes('5')
    }
  }
}
</script>

<style lang="less" scoped>
.warehouse-container{
  background-color: #FFFFFF;
  .warehouse-title{
    font-weight: 700;
    color: #222222;
    font-size: 16px;
    flex: 1;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    .mall-name{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      margin-right: 8/75rem;
    }
  }
  .warehouse-content{
    padding: 20/75rem;
    display: flex;
    align-items: center;
    background-image: url("/pwa_dist/images/cart/sui_img_mall_bg-dd599b1e8f.png");
    background-color: #f6f6f6;
    background-size: cover;
  }
}
</style>
