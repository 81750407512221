<template>
  <div
    id="cart-app"
    class="j-cart-container cart-container__warpper"
    :class="{isIPhoneX: isIPhoneX}"
    da-expose-code="page-cart-expose-events"
  >
    <!-- Header -->
    <cart-header
      :is-s-s-r="isSSR"
      @login-handle="clickLogin"
    />
    <CartHeaderContent
      v-if="carts && carts.length"
      :is-s-s-r="isSSR"
    />
    <!-- page loading -->
    <div
      v-if="pageLoading && !isSSR"
      class="c-skeleton-cart-container"
    >
      <s-loading
        type="newpage"
        :show="true"
        :new-page-delay="false"
      />
    </div>
    <cart-list
      v-if="hasFetchCart && carts && carts.length"
      :is-s-s-r="isSSR"
      :event-expose="daEventExpose"
      :render-all="renderCartListAll"
      @cartListIsMounted="cartListIsMounted"
      @noMoreItemRender="noMoreItemRender"
      @renderRecommend="renderRecommend"
    />
    <!--空购物袋-->
    <cartEmpty
      v-if="hasFetchCart && carts && carts.length == 0"
      @clickLogin="clickLogin"
    >
      <!-- <ClientOnly> -->
      <template v-if="!IS_RW">
        <empty-cart-operate v-if="isEmptyUnpaidOrder && isShowCartOrder" />
      </template>
      <!-- </ClientOnly> -->
    </cartEmpty>
    <ClientOnly>
      <CartSupportInfoContainer
        v-if="rendSupportInfo && showPaymentSafetyCart"
        :has-fetch-cart="hasFetchCart"
      />
    </ClientOnly>
    <!-- 未支付订单 -->
    <CartOrderWaitingForPayment v-if="isShowCartOrder" />
    <ClientOnly>
      <EmptyCartCanceledOrderTip v-if="isShowCanceledOrder"/>
    </ClientOnly>
    <recommend-wrapper
      v-if="!pageLoading && hasFetchCart"
      v-show="!hiddenRecommend"
      id="j-cart-container__recommend"
      ref="recommendWrapper"
      class="cart-container__recommend"
      :has-fetch-cart="hasFetchCart"
      :should-reload="shouldReloadRecommend"
      :need-render="needRender" 
      @reload-success="handleRecommendReloadSucess"
    />

    <!-- 通用的蒙层 -->
    <div
      v-show="checkoutMask"
      class="c-cart__mask-checkout mshe-mask"
      @click="clickMask"
    ></div>

    <!-- 底部checkout -->
    <checkout-vue
      v-if="hasFetchCart && carts.length"
      ref="cartCheckout"
      :show-coupon-helper-enter="showCouponHelperEnter"
    />
    <ClientOnly>
      <!-- 批处理 -->
      <transition name="vue-slide-layer-bottom-fade">
        <cart-batch-footer v-if="batchActive" />
      </transition>

      <!-- 售罄商品、优惠券助手组件滑出层按需加载     -->
      <keep-alive>
        <component
          :is="dialogComponent.other"
        />
      </keep-alive>
      <full-gift-drawer v-if="asyncComponentLoadedStatus.cartFullGiftsDrawIsLoaded" />
      <add-buy-drawer v-if="asyncComponentLoadedStatus.cartAddBuyDrawIsLoaded" />
      <buy-gift-drawer v-if="asyncComponentLoadedStatus.cartBuyGiftDrawIsLoaded" />
      <club-gift-drawer v-if="asyncComponentLoadedStatus.cartClubGiftDrawIsLoaded" />
      <EstimatedDetailDrawer v-if="asyncComponentLoadedStatus.cartEstimatedDetailDrawIsLoaded" />
      <CanceledOrderProductList 
        v-if="asyncComponentLoadedStatus.canceledOrderProductListIsLoaded"
        @add-success="handleAddSuccess"
      />
      <!--  凑单组件按需加载    -->
      <CartAddItemNew v-if="asyncComponentLoadedStatus.addForCouponIsLoaded" />

      <!--  通用提示组件按需加载    -->
      <component :is="modalOptions.component" />
      <ShareGuide
        v-if="showShareGuide"
        :language="language"
      />
      <DiscountNewGuide
        v-if="showDiscountNewGuide"
        :language="language"
      />
      <PromoGuide
        v-if="showPromoGuide"
        :language="language"
      />
      <!-- 领券弹窗 -->
      <get-coupon-drawer v-if="carts.length && !isSSR && loadGetCoupon" />
      <CartShareDraw v-if="asyncComponentLoadedStatus.cartShareDrawIsLoaded" />

      <NoNetworkEmpty
        class="cart-container__no-network-empty"
        :is-empty="hasFetchCart && carts && carts.length == 0"
        :language="language"
      />
    </ClientOnly>
    <s-loading
      v-if="!isSSR"
      type="curpage"
      mask-transparent
      :show="loadingShow"
    />
    <cart-negative-information v-if="negativeInformationMallCode" />
    <DeleteTips />
    <span
      id="cartsID"
      class="hide"
    >
      {{ cartsId.slice(0,6).join(',') }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import ClientOnly from 'vue-client-only'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import cartMixin from './mixins/cart-mixin'

/** 组件 **/
import CartHeader from './components/header/cartHeader.vue'
import checkoutVue from './components/CartCheckout.vue'
import CartEmpty from './components/CartEmpty.vue'
import CartList from './components/list/CartList.vue'

/** Event listener **/
import { bindContainerScrollListener } from './eventListener/scroll'
import { bindHashChangeListener } from './eventListener/hash'

/** Utils **/
import { judgeActivityIsExist, cartEmphasizePromotionAll, getBrowserName, scrollToCartItem } from './utils'
import { getCouponListForCart, queryLatestUnPaidOrderInfo, geCartCouponList } from './utils/fetcher'
import { getQueryString, removeLocalStorage } from '@shein/common-function'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { posKeys, newPosKeys } from './config'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Slide, TitleNav, TitleNavItem, Button, ButtonGroup, Dialog, ButtonGroupItem, Drawer, Popover, Alert, Checkbox, CheckboxGroup, CheckboxTag, Loading } from '@shein/sui-mobile'
import DeleteTips from './components/deleteTips/DeleteTips.vue'
import prerenderCheckout from 'public/src/pages/cartNew/utils/prerender.js'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { queryMallInfo } from 'public/src/pages/product_app/store/modules/cart_new/api.js'
import { isFreeShippingCoupon } from 'public/src/pages/components/coupon/mall/utils'
import { getAvailableCoupon } from 'public/src/pages/cartNew/utils/cartFilterLabels.js'
import { detectUserInteraction, isTimestampWithinDuration, INCENTIVE_TYPES, POPOVER_CONFIGS } from './components/cartCheckoutIncentive/incentiveTools.js'
import { getCouponDataForAddOnItem } from '@/public/src/pages/common/addOnItem/utils/couponData.js'
import { handlerCouponSaveToastByBff } from './utils/couponSaveToast.js'
import { USE_BFF_API_KEYS } from './utils/constants'
import { web_cart_route_enter } from './utils/metricTagsConfig.js'
import { isEmpty } from '@shein/common-function'
import { updateSessionOrderCache } from 'public/src/pages/cartNew/components/canceledOrderTip/canceledOrderTools.js'
import { MonitorUserInteraction, insertRecommendGuideB, checkRecommendInView } from 'public/src/pages/cartNew/components/recommendGuide/utils.js'

Vue.use(Button)
Vue.use(Slide)
Vue.use(Dialog)
Vue.use(ButtonGroup)
Vue.use(ButtonGroupItem)
Vue.use(Drawer)
Vue.use(Popover)
Vue.use(Alert)
Vue.use(TitleNav)
Vue.use(TitleNavItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
// Vue.use(Sticky)
Vue.use(CheckboxTag)
Vue.use(Loading)

Vue.component('ClientOnly', ClientOnly)
Vue.component(
  'CountDownTime',
  () => import('./components/CartCountDown.vue')
)

import { register } from 'swiper/element'
if (typeof window !== 'undefined') {
  register()
}

let isRouterLeaved = false

daEventCenter.addSubscriber({ modulecode: '1-8-1' })
daEventCenter.addSubscriber({ modulecode: '1-8-2' })
daEventCenter.addSubscriber({ modulecode: '1-8-7' })
let SaPageInfo = {}
export default {
  name: 'CartContainer',
  components: {
    CartHeader,
    CartList,
    checkoutVue,
    cartEmpty: CartEmpty,
    CartHeaderContent: () => import(/* webpackChunkName: "cart_header_checkout" */'./components/header/index.vue'),
    recommendWrapper: () => import(/* webpackChunkName: "cart_bottom_content" */'./recommendWrapper/index.vue'),
    CartOrderWaitingForPayment: () => import(/* webpackChunkName: "cart_header_checkout" */'./components/CartOrderWaitingForPayment.vue'),
    EmptyCartOperate: () => import(/* webpackChunkName: "cart_bottom_content" */'./components/EmptyCartOperate.vue'),
    CartBatchFooter: () => import('./components/CartBatchFooter.vue'),
    getCouponDrawer: () => import(/* webpackChunkName: "cart-get-coupon-drawer" */'./components/coupon/getCoupon/drawer.vue'),
    soldOutVue: () => import(/* webpackChunkName: "cart-sold-out" */'./vue_tpls/sold_out.vue'),
    FullGiftDrawer: () => import(/* webpackChunkName: "cart-promotion-drawer" */'./components/promotion/FullGiftDrawer.vue'),
    AddBuyDrawer: () => import(/* webpackChunkName: "cart-promotion-drawer" */'./components/promotion/AddBuyDrawer.vue'),
    BuyGiftDrawer: () => import(/* webpackChunkName: "cart-promotion-drawer" */'./components/promotion/BuyGiftDrawer.vue'),
    EstimatedDetailDrawer: () => import(/* webpackChunkName: "cart-estimated-detail-drawer" */'./components/EstimatedDetailDrawer/index.vue'),
    CartCouponHelper: () => import(/* webpackChunkName: "cart-coupon-helper" */'./components/coupon/CouponHelper/Drawer.vue'),
    CartAddItemNew: () => import(/* webpackChunkName: "cart-add-on" */'./components/cartAddItem/indexNew.vue'),
    commonModalOptions: () => import(/* webpackChunkName: "cart-common-modal-options" */'./components/commonModalOptions.vue'),
    CartNegativeInformation: () => import(/* webpackChunkName: "cart-item-negative" */'./components/CartNegativeInformation.vue'),
    CartShareDraw: () => import(/* webpackChunkName: "cart_share_draw" */'./components/cartShareDraw/index.vue'),
    CartSharePage: () => import(/* webpackChunkName: "cart_share_page" */'./sharePage/index.vue'),
    ShareGuide: () => import(/* webpackChunkName: "cart_share_guide" */'./components/cartShareDraw/ShareGuide.vue'),
    DeleteTips,
    DiscountNewGuide: () => import(/* webpackChunkName: "discount-new-guide" */'./components/checkoutDiscountDetails/DiscountNewGuide.vue'),
    ClubGiftDrawer: () => import(/* webpackChunkName: "cart-promotion-drawer" */'./components/promotion/ClubGiftDrawer.vue'),
    NoNetworkEmpty: () => import(/* webpackChunkName: "cart-no-network-empty" */'./components/offline/NoNetworkEmpty.vue'),
    EmptyCartCanceledOrderTip: () => import(/* webpackChunkName: "empty-cart-canceled-order-tip" */'./components/canceledOrderTip/EmptyCartCanceledOrderTip.vue'),
    CanceledOrderProductList: () => import('./components/canceledOrderTip/CanceledOrderProductList.vue'),
    PromoGuide: () => import(/* webpackChunkName: "discount-new-guide" */'public/src/pages/cartNew/components/cartActivityNew/PromoGuide.vue'),
    CartSupportInfoContainer: () => import(/* webpackChunkName: "cart-support-info-container" */'./components/CartSupportInfoContainer'),
  },
  mixins: [cartMixin],
  async asyncData ({ store, context }) {
    store.commit('cart/resetLocals')
    if (context && context.ssrPageType === 'cart') {
      store.state['cart'] = Object.assign(store.state['cart'], context)//updateGbSsrData
      // store.commit('cart/updateState', { key: 'loginStatus', value: context.loginStatus ||g false})
      await store.dispatch('cart/initSsrData')
    } else if(typeof window != 'undefined' && window.cartSsrData){
      store.state['cart'] = Object.assign(store.state['cart'], window.cartSsrData)//updateGbSsrData
      // store.commit('cart/updateState', { key: 'loginStatus', value: window.cartSsrData.loginStatus || false })
      await store.dispatch('cart/initSsrData')
    }
    return 'uninstall'
  },
  data: function () {
    return {
      isSSR: false,
      isFirstPageLoading: true,
      pageLoading: false,
      hasFetchCart: false,
      showCouponHelperEnter: false,
      daEventExpose: null,
      loadGetCoupon: false,
      shouldReloadRecommend: false, // 应该去刷新推荐位（scene_id: 24）
      cartListMounted: false,
      needRender: false,
      outOfView: false,
      hiddenRecommend: false,
      hasGoodsOnInitialLoad: false, // 首次加载的时候是否有商品
      renderCartListAll: false,
      canShowGuide: true, // 是否能展示所有的引导
      rendSupportInfo: false,
      canShowIncentivePopover: false, // 是否能展示结算按钮利诱点气泡
    }
  },
  computed: {
    ...mapGetters('cart', ['carts', 'fullCourtPromotionTip', 'CouponAddonStatus', 'couponAddon', 'couponDiscount', 'needFlushPrerenderCache', 'couponSelectType', 'payShowStyleA', 'payShowStyleB', 'checkoutFreeShippingState', 'topShippingInfo', 
      'incentiveTimeConfig',
      'normalCartItemIds',
      'normalCartItems',
      'cartCouponEntranceLog',
      'showFreeShipping4RightsFuns',
      'cartRecommendGuideConfig',
      'couponFilterIsCsc',
      'isShowNewPromoBar',
      'cartfloorInform',
    ]),
    ...mapState('cart', [
      'cartsId',
      'integrityPromotionInfo',
      'prerenderSsr',
      'ssrPageType',
      'isPlatForm',
      'loginStatus',
      'language',
      'cartInfo',
      'batchActive',
      'enterCartTimes',
      'modalOptions',
      'checkoutMask',
      'dialogComponent',
      'cartAbtInfo',
      'loadingShow',
      'firstFreeShipping',
      'pageStatus',
      'negativeInformationMallCode',
      'drawerGetCoupon',
      'bodyFixedTop',
      'filterType',
      'soldoutIds',
      'asyncComponentLoadedStatus',
      'realtimeBehaviorLabels',
      'couponInfo',
      'targetCouponInfo',
      'cartLeave',
      'unPaidOrderInfo',
      'showIncentiveProductListDialog',
      'couponFilterLabelData',
      'inducementInfoList',
      'inducementSortList',
      'showIncentivePopover',
      'showIncentiveTimes',
      'incentivePopoverSwitchTimer',
      'useBffApi',
      'incentivePopoverKeepTimer',
      'canceledOrderInfo',
      'isNewUserOfTsp',
      'showRecommendGuideA',
      'monitorUserInteraction',
      'couponLabelFromCsc',
      'canShowPromoGuide',
      'isFreeReturn',
      'lowinventoryItemInfo',
    ]),
    ...mapState({
      rootCartInfo: state => state.cartInfo,
    }),
    isShowCartOrder() {
      return this.hasFetchCart && this.carts?.length == 0 && this.loginStatus
    },
    isShowCanceledOrder() {
      return this.hasFetchCart && this.carts?.length == 0 && this.loginStatus && !(this.unPaidOrderInfo?.orderData?.billno) && this.canceledOrderInfo?.billno
    },
    showNotice() {
      return !(this.carts?.length && !this.loginStatus)
    },
    isEmptyUnpaidOrder() {
      return !this.unPaidOrderInfo?.orderData?.billno
    },
    cartCheckedNum () {
      let checkedNum = 0
      this.carts.filter(item => item.is_checked == 1).forEach(cartItem => {
        checkedNum += cartItem.quantity
      })
      return checkedNum
    },
    showShareGuide() {
      return this.canShowGuide && !this.batchActive && this.carts?.find(item => !this.soldoutIds.includes(item.id)) && !gbCommonInfo?.IS_RW && this.hasFetchCart && this.hasGoodsOnInitialLoad
    },
    hasAppendagePromotion(){
      return Object.values(this.integrityPromotionInfo).filter(item => [2, 4, 28, 13, 22].includes(+item.type_id))
    },
    showDiscountNewGuide() {
      let showedShareGuide = false
      let showedPromoGuide = false
      if(typeof window != 'undefined') {
        showedShareGuide = window.localStorage.getItem('newShareGuide') || window.localStorage.getItem('newShareGuideV2')
        showedPromoGuide = this.isShowNewPromoBar ? window.localStorage.getItem('PromoCouponMixGuide') : true
      }
      return this.canShowGuide && this.cartAbtInfo?.EstimatedDetailSwitch?.param?.estimated_detail_guidance_switch == 'on' && showedShareGuide && showedPromoGuide
    },
    showPromoGuide() { // 判断是否具备展示活动新人指引的条件
      if (typeof window === 'undefined') return false
      const showedShareGuide =  window.localStorage.getItem('newShareGuideV2')
      return this.canShowGuide && showedShareGuide && this.canShowPromoGuide
    },
    showPaymentSafetyCart() {
      return this.cartAbtInfo?.PaymentSafetyExperiment?.param?.PaymentSafetyCart == 'Show' && this.carts?.length > 0 && this.hasFetchCart
    },
  },
  watch: {
    needFlushPrerenderCache: {
      handler (val) {
        if(typeof window == 'undefined') return
        prerenderCheckout.switchTaskState(val)
      },
      immediate: true
    },
    cartCheckedNum: {
      handler (n, o) {
        if(typeof window != 'undefined' && (n != o)){
          this.setCartCouponHelperDefault()
        }
      },
      immediate: true
    },
    drawerGetCoupon(n){
      if(n) this.loadGetCoupon = true
    },
    CouponAddonStatus() {
      if (this.CouponAddonStatus && !this.cartCouponEntranceLog) {
        this.showCouponHelperEnter = false
      }
      // else 的情况, 当优惠券头部不可展示是, 是因cart变化引起, 因此通过原有函数,有机会再次展示
    },
    pageStatus: {
      handler(newValue) {
        if (Object.keys(newValue).some(key => newValue[key])) {
          this.hashDialogOpen = true
        }
        // 所有带hash跳转的弹窗关闭后恢复滚动条位置
        if (this.hashDialogOpen && Object.keys(newValue).every(key => !newValue[key])) {
          this.hashDialogOpen = false
          const beforeActivateCarts =  this.carts?.length || 0
          const newActivateCarts = this.rootCartInfo?.carts?.length || 0
          const haveAddCart = newActivateCarts > beforeActivateCarts // 有加车动作（包含了从无到有）
          const haveItemsToEmpty = beforeActivateCarts > 0 && newActivateCarts === 0 // 是否从有到无
          if (haveAddCart || haveItemsToEmpty) return
          const top = this.bodyFixedTop || 0 
          this.$nextTick(() => {
            window.scrollTo({ top })
          })
        }
      },
      deep: true
    },
    'unPaidOrderInfo.orderData.billno': {
      handler(n) {
        if(typeof window == 'undefined') return
        // 客户端兜底, 若没有未支付订单信息, 则尝试获取
        if(!n) this.fetchUnPaidOrdersInfo()
      },
      immediate: true,
    },
    isShowCanceledOrder: {
      handler(n, o) {
        if(typeof window == 'undefined') return
        if(n && !o) {
          updateSessionOrderCache(this.canceledOrderInfo?.billno)
        }
      },
      immediate: true
    },
    isShowCartOrder: {
      handler(n, o) {
        if(typeof window == 'undefined') return
        if(n && !o) {
          updateSessionOrderCache(this.unPaidOrderInfo?.orderData?.billno)
        }
      },
      immediate: true
    },
    couponFilterLabelData: {
      handler(val) {
        if(typeof window == 'undefined' || this.couponFilterIsCsc) return
        const couponLabels = getAvailableCoupon(val, this.language)
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            coupon_select: couponLabels?.length ? 1 : 0
          }
        })
      },
      immediate: true
    },
    couponLabelFromCsc: {
      handler(val) {
        if(typeof window == 'undefined' || !this.couponFilterIsCsc) return
        const couponLabels = val?.userReceiveCouponList
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            coupon_select: couponLabels?.length ? 1 : 0
          }
        })
      },
      immediate: true
    },
    topShippingInfo: {
      handler(newVal) {
        const type = isEmpty(newVal) ? 0 : 1
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'cart_shipping_position',
            type
          }
        })
      }
    },
    filterType: {
      handler() {
        this.monitorUserInteraction.stop({ reason: 'click filter tag' })
      }
    }
  },
  created () {
    const ssrPageType = this.$store.state['cart'].ssrPageType || []
    if(ssrPageType === 'cart' || (typeof window != 'undefined' && window.cartSsrData)) {
      this.isSSR = true
      this.hasFetchCart = true
    } else {
      this.pageLoading = true
    }
    prerenderCheckout.init()
  },
  beforeRouteEnter (to, from, next) {
    isRouterLeaved = false
    next(vm => {
      vm.handleBeforeRouteEnter(to)
      if(typeof $ != 'undefined'){
        $('.j-index-footer').css({ display: 'none' })
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if(to.name == 'checkout' && !window.toCheckout) {
      this.$refs.cartCheckout?.goCheckout('conventional', { next: () => {
        this.handleBeforeRouteLeave()
        next()
      } })
      return
    } else if (to.name !== 'checkout') {
      prerenderCheckout.flushJob()
    }
    this.handleBeforeRouteLeave()
    next()
  },
  async activated () {
    window.TPM.TPES.switchPage('page_cart')
    this.updateLocals({
      USER_LOCAL_SIZE_COUNTRY: window.localStorage.getItem('last_select_country') || '',
    })
    if(!this.isFirstPageLoading) {
      this.updateAddressLocal(true)
    }
    removeLocalStorage('is_combine')
    this.resetSaPageInfo()
    this.fetchIncludeTaxInfo({
      siteUid: gbCommonInfo?.SiteUID || '',
      lang: gbCommonInfo?.lang || '',
    })
    this.isHaveAddCart()
    this.handlerCouponSaveToast()

    if(this.isSSR) {
      this.isSSR = false
      this.updateState({ key: 'enterCartTimes', value: this.enterCartTimes + 1 })
      this.handleSaPageInfo({ SaPageInfo })
      this.hasFetchCart = true
      this.hasGoodsOnInitialLoad = this.carts?.length > 0
      this.handlePageView(this.cartInfo)
      if (window.sessionStorage) {
        window.sessionStorage.setItem('lastViewCartItem', '')
      }
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      this.handleQueryCartData(this.cartInfo)
      return
    }

    let isLogin = isLoginFn()
    this.updateState({
      key: 'loginStatus',
      value: isLogin
    })
    const fetchCartFirstScreenSourcePromise = this.$route.meta?.fetchCartFirstScreenSourcePromise || Promise.all([queryMallInfo()])

    const firstScreenSource = await fetchCartFirstScreenSourcePromise
    const abtInfo = await (window.fetchCartFirstScreenAbtSourcePromise || this.fetchCartAbtInfo({ posKeys: posKeys.join(','), newPosKeys: newPosKeys.join(), SaPageInfo }))

    this.evaluateFirstScreenSource({
      firstScreenSource,
      SaPageInfo,
      abtInfo
    })
    // await Promise.all([this.fetchPromotionInfo(), this.initAbtInfo(), this.fetchSiteMallInfo() ])
    requestAnimationFrame(() => {
      markPoint('queryCartData', 'public')
      this.queryCartData({} ).then( (data) => {
        markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
        // 路由离开了，不再处理内容
        if (isRouterLeaved) return

        // 重置itemSwiper
        this.updateState({ key: 'openedDeleteItemId', value: '' })

        this.handleQueryCartData(data)
        this.fetchIncludeTaxInfo({
          siteUid: gbCommonInfo?.SiteUID || '',
          lang: gbCommonInfo?.lang || '',
        })
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'autopoint',
            max_available_point: this.cartInfo?.auto_use_points_info?.max_available_points || 0,
            auto_point_result: this.cartInfo?.auto_use_points_info?.auto_use_points > 0 ? 1 : 0,
            is_below_auto_use_point_threshold: this.cartInfo?.auto_use_points_info?.is_below_auto_use_point_threshold || 0,
          }
        })
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'cart_rights_funs',
            type: this.isNewUserOfTsp
              ? (this.showFreeShipping4RightsFuns || this.isFreeReturn) ? 'newuser' : ''
              : (this.showFreeShipping4RightsFuns && this.isFreeReturn)
                ? 'freereturn'
                : this.showFreeShipping4RightsFuns ? 'freeship' : ''
          }
        })
        if(this.lowinventoryItemInfo?.itemIds?.length >= 2) {
          daEventCenter.triggerNotice({
            daId: '1-8-7-60',
            extraData: {
              scenes: 'low_inventory',
              type: '-',
            }
          })
        }
      })
    })

    this.paymentFrontShowExpose()
    this.scrollToTopOnQuery()
    // 若低效用户底部推荐引导倒计时在离开购物车时还未结束，则在返回购物车时重新计时
    if (this.monitorUserInteraction && this.monitorUserInteraction.overTime === null) {
      console.log(`[cart低效状态] 返回购物车，重新计时。`)
      this.monitorUserInteraction.interactionTimer = this.monitorUserInteraction.setInteractionTimer()
    }
  },
  beforeMount () {
    if(this.prerenderSsr){
      this.$el = document.querySelector('#prerender-app #cart-app')
    }
  },
  mounted: function () {
    this.exchangingSpaScene(false)
    this.fetchIncludeTaxInfo({
      siteUid: gbCommonInfo?.SiteUID || '',
      lang: gbCommonInfo?.lang || '',
    })
    window.CartVue = this
    appEventCenter.$on('update-cart', async (data = {}, cb) => {
      if (this.cartLeave) return
      if (!data.noFetch) {
        await this.asyncUpdateCartInfo({ cartInfo: data.info })
      }
      // 更新购物车
      this.setCartSaPageInfoParams()
      cb && cb()
    })
    //Safari 返回不刷新
    let self = this
    var browserRule = getBrowserName()
    if(browserRule == 'Apple Safari'){
      window.onpageshow = function(e){
        if(e.persisted || (window.performance && window.performance.navigation?.type == 2) ){
          self.changeRootSLoadingStatus({
            show: false
          })
          self.fetchCarts()
          console.log(' close loading')
        }
      }
    }
    daEventCenter.triggerNotice({
      daId: '1-8-7-60',
      extraData: {
        scenes: 'autopoint',
        max_available_point: this.cartInfo?.auto_use_points_info?.max_available_points || 0,
        auto_point_result: this.cartInfo?.auto_use_points_info?.auto_use_points > 0 ? 1 : 0,
        is_below_auto_use_point_threshold: this.cartInfo?.auto_use_points_info?.is_below_auto_use_point_threshold || 0,
      }
    })

    daEventCenter.triggerNotice({
      daId: '1-8-7-60',
      extraData: {
        scenes: 'cart_rights_funs',
        type: this.isNewUserOfTsp
          ? (this.showFreeShipping4RightsFuns || this.isFreeReturn) ? 'newuser' : ''
          : (this.showFreeShipping4RightsFuns && this.isFreeReturn)
            ? 'freereturn'
            : this.showFreeShipping4RightsFuns ? 'freeship' : ''
      }
    })
    
    if(this.lowinventoryItemInfo?.itemIds?.length >= 2) {
      daEventCenter.triggerNotice({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'low_inventory',
          type: '-',
        }
      })
    }
    this.paymentFrontShowExpose()
    this.getUseBffApi(USE_BFF_API_KEYS)
    
    requestIdleCallback(() => {
      if(this.hasAppendagePromotion.length) {
        this.asyncComponentLoadedStatus.cartFullGiftsDrawIsLoaded = true
      }
      prefetchResource.listen({
        el: this.$el,
        prefetchList: [
          {
            chunkName: 'add_on_container',
            relType: 'prefetch'
          },
        ],
      })
    }, { timeout: 2000 })

    this.scrollToTopOnQuery()
    this.fetchCanceledOrderInfo()
    // 低客单拦截埋点 wiki.pageId=1182724147
    // const { action } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
    // typeof action !== 'undefined' && daEventCenter.triggerNotice({
    //   daId: '1-8-7-60',
    //   extraData: {
    //     scenes: 'lower_price',
    //     action,
    //   }
    // })
  },
  methods: {
    ...mapActions('cart', ['updateGbSsrData', 'asyncUpdateCartInfo', 'fetchCartAbtInfo', 'handleCartLogin', 'initSsrData', 'handleSaPageInfo', 'fetchPromotionGoodsComments', 'fetchSiteMallInfo', 'fetchCarts', 'fetchPromotionInfo', 'evaluateFirstScreenSource', 'fetchIncludeTaxInfo', 'handleCartFilterLabels', 'sortCartInducementInfo', 'getUseBffApi', 'clearIncentivePopover', 'fetchCanceledOrderInfo', 'fetchRightsFunsInfoInClient', 'clearIncentivePopoverTimer']),
    ...mapMutations('cart', ['updateState', 'changePageStatus', 'resetPageStatus', 'setCartSaPageInfoParams', 'resetLocals', 'updateAddressLocal', 'updateBottomActivityConfig', 'updateLocals']),
    ...mapMutations(['changeRootSLoadingStatus', 'exchangingSpaScene']),
    clickMask () {
      this.updateState({
        key: 'showDiscountDetails',
        value: false
      })
      if(this.showIncentiveProductListDialog) {
        this.updateState({
          key: 'showIncentiveProductListDialog',
          value: false
        })
        // 若发现低库存中有利诱缓存, 则从缓存中恢复数据, 并清空缓存
        if(this.lowinventoryItemInfo?.incentivePopoverContent?.type) {
          this.updateState({
            key: 'incentivePopoverContent',
            value: {
              ...(this.lowinventoryItemInfo?.incentivePopoverContent || {}),
              lowInventory: false,
            }
          })
          this.updateState({
            key: 'incentiveItemIds',
            value: Array.from(this.lowinventoryItemInfo?.incentiveItemIds)
          })
          this.updateState({
            key: 'lowinventoryItemInfo',
            value: {
              ...(this.lowinventoryItemInfo || {}),
              incentivePopoverContent: undefined,
              incentiveItemIds: undefined,
            }
          })
        }
        if(this.showIncentiveTimes < 3 && (this.inducementSortList?.length || 0) - 1 >= this.showIncentiveTimes) {
          if(this.incentivePopoverSwitchTimer) {
            clearTimeout(this.incentivePopoverSwitchTimer)
            this.updateState({
              key: 'incentivePopoverSwitchTimer',
              value: null
            })
          }
          const incentiveGapTime = this.incentiveTimeConfig?.incentiveGapTime || 1000 * 60
          this.updateState({
            key: 'incentivePopoverSwitchTimer',
            value: setTimeout(() => {
              this.updateState({
                key: 'showIncentivePopover',
                value: true
              })
            }, incentiveGapTime)
          })
        }
      }
    },
    resetBatchItems () {
      this.updateState({ key: 'batchEditItemIds', value: [] })
      this.updateState({ key: 'batchActive', value: false })
      this.updateState({ key: 'flashSaleOverLimit', value: {} })
    },
    handleBeforeRouteEnter (to) {
      if(typeof window != 'undefined') {
        web_cart_route_enter()
        window.cart_opt_time = []
        window.cart_prime_product_code = ''
        window.toCheckout = false
        $('.j-uptt').css('zIndex', '11') // 解决一键回到顶部按钮层级被覆盖的问题
      }
      this.updateState({ key: 'enterCartTimes', value: this.enterCartTimes + 1 })
      this.updateState({ key: 'cartLeave', value: false })
      this.resetBatchItems()
      if (this.outOfView) {
        this.outOfView = false
        // this.hiddenRecommend = true
      }

      this.sortCartInducementInfo()
      this.handleCartRouteEnterAction(to)
    },
    handleCartRouteEnterAction(to) {
      // 每次进入恢复成可展示指引状态
      this.canShowIncentivePopover = true
      this.canShowGuide = true 
      const { type, products, cartId } = to?.params
      const { from } = to?.query ?? {}

      // 购物车楼层过来不展示任何东西
      if (from === 'cart_floor' && this.cartfloorInform) {
        this.canShowGuide = false
        this.canShowIncentivePopover = false
        return
      }

      // 进入购物车滚动到商品并高亮
      if (cartId) {
        this.anchorToCartItem(cartId)
      }

      // 利诱信息弹窗
      if (!INCENTIVE_TYPES.includes(type)) return
      this.clearIncentivePopover()
      this.canShowGuide = false

      // 打开优惠明细并高亮
      if (
        type == 'coupon' ||
        (type === 'gift' && products?.length)
      ) {
        this.$nextTick(() => {
          this.$refs.cartCheckout?.openCheckoutDiscount(type)
        })
        return
      }
      
      let cartIds = []
      if (type === 'credit_reward') {
        cartIds = (this.normalCartItems || []).filter(v => v.product_credit_flag == 1).map(v => v.id)
      } else {
        cartIds = products.filter((id) => this.normalCartItemIds?.includes(id))
      }
      
      if (cartIds?.length > 1) {
        this.openIncentiveListDialog({ 
          type,
          idArr: cartIds,
        })
      } else if (cartIds?.length === 1) {
        this.anchorToCartItem(cartIds[0])
      }
    },
    // 锚定到某个购物车某个商品
    anchorToCartItem(cartId) {
      if (!cartId) return 
      this.canShowGuide = false
      this.renderCartListAll = true
      this.$nextTick(() => {
        scrollToCartItem({
          id: cartId,
          smooth: true,
          highlightAnimate: true,
        })
      })
    },
    // 打开利诱弹窗
    openIncentiveListDialog({ type, idArr }) {
      if (!idArr?.length) return
      this.updateState({ 
        key: 'incentivePopoverContent', 
        value: {
          type,
          ...(POPOVER_CONFIGS[type] || {})
        }
      })
      this.updateState({ key: 'incentiveItemIds', value: idArr })
      setTimeout(() => {
        this.updateState({ key: 'showIncentiveProductListDialog', value: true })
        this.clearIncentivePopover()
      }, 300)
    },
    handleBeforeRouteLeave () {
      /** 迁下来 */
      console.log('-------- Leave Cart')
      isRouterLeaved = true
      this.outOfView = true
      $('body').removeClass('mshe-fixed')
      setTimeout(() => {
        // 解决CartCheckout.vue的checkoutHeightUpdate问题
        $('.j-uptt').css('visibility', 'visible')
        $('.j-uptt').css({ bottom: 'var(--scoll-to-top-bottom)' })
      }, 0)
      // 当用户在缓存周期内未产生加车行为，再次进入购物车，则保持原浏览坑位深度（不刷新）
      this.scrollPosition = document.documentElement.scrollTop || window.pageYOffset
      // window.toCheckout = false

      // 低客单拦截埋点 wiki.pageId=1182724147
      // const { action } = this.cartInfo?.touch_rule_list?.find(i => i.hint_type == 5) || {}
      // typeof action !== 'undefined' && daEventCenter.triggerNotice({
      //   daId: '1-8-7-60',
      //   extraData: {
      //     scenes: 'lower_price',
      //     action,
      //   }
      // })
      /** END */

      window.cart_opt_time = []
      if (window.sessionStorage) {
        window.sessionStorage.setItem('cart_page_from', window.SaPageInfo.page_from)
      }
      this.updateState({ key: 'cartLeave', value: true })
      this.updateState({ key: 'lastCheckStockTime', value: 0 })
      this.updateState({ key: 'showIncentivePopover', value: false })
      if(this.incentivePopoverSwitchTimer) {
        clearTimeout(this.incentivePopoverSwitchTimer)
        this.updateState({
          key: 'incentivePopoverSwitchTimer',
          value: null
        })
      }
      if(this.incentivePopoverKeepTimer) {
        clearTimeout(this.incentivePopoverKeepTimer)
        this.updateState({
          key: 'incentivePopoverKeepTimer',
          value: null
        })
      }
      // 离开购物车时，若低效用户底部推荐引导倒计时还未结束，则取消计时
      if (this.monitorUserInteraction && this.monitorUserInteraction.overTime === null) {
        console.log(`[cart低效状态] 离开购物车，取消计时。`)
        clearTimeout(this.monitorUserInteraction.interactionTimer)
        this.monitorUserInteraction.interactionTimer = null
      }
    },
    async clickLogin (type) {

      if (type === 'banner') {
        daEventCenter.triggerNotice({ daId: '1-8-7-1' })
      }
      const { result, res } = await this.handleCartLogin({
        type,
        updateSingelDataCb: () => {
          // 登录前为空车, 则在数据返回前展示loading
          if(this.cartInfo.cartSumQuantity == 0) this.pageLoading = true
        }
      })
      if(res?.code == 0){
        await this.asyncUpdateCartInfo({ cartInfo: res.info })
        this.fetchIncludeTaxInfo({
          siteUid: gbCommonInfo?.SiteUID || '',
          lang: gbCommonInfo?.lang || '',
        })
        if (!result.equalCart && !result.emptyCart) {
          this.$toast(this.language.SHEIN_KEY_PWA_17961, 5000)
        }
        this.fetchUnPaidOrdersInfo()
        this.initAbtInfo()
      }
      this.pageLoading = false
    },
    onRefreshCartData () {
      this.queryCartData({ noSkeleton: true })
    },
    setCartData (data) {
      if (data) {
        this.setCartSaPageInfoParams({ SaPageInfo, cartInfo: data })

        if (this.isFirstPageLoading) {
          // 依赖初始数据只需处理一次的事件
          bindHashChangeListener.call(this)
          bindContainerScrollListener.call(this)
          this.isFirstPageLoading = false
        }
      }
    },
    queryCartData (options = {}) {
      const { force = false } = options
      options.cb = this.handlePageView

      const showLoading = force || this.carts.length == 0

      if (showLoading) this.pageLoading = true

      return new Promise((resolve, reject) => {
        this.updateGbSsrData(options).then(data => {
          this.hasFetchCart = true
          this.hasGoodsOnInitialLoad = this.carts?.length > 0
          let cartLength = data?.carts?.length || 0
          if(cartLength == 0 || this.cartListMounted) {
            this.pageLoading = false
          }
         
          if (data) {
            resolve(data)
          } else {
            reject(data)
          }
        })
      })
    },
    // 开启券筛选时，当商品信息发生变化，更新targetCouponInfo
    updateTargetCoupon(data) {
      const currentCouponId = this.targetCouponInfo?.id
      if (currentCouponId) {
        let { usableCouponList = [], disabledCouponList = [] } = data || {}
        const coupon = [...disabledCouponList, ...usableCouponList]
        const targetCoupon = coupon?.find(i => i.id == currentCouponId)
        if (targetCoupon?.id) {
          targetCoupon['couponType'] = targetCoupon.coupon_type_id == '1' 
            ? `coupon_money_${targetCoupon.coupon}`
            : `coupon_off_${targetCoupon.coupon}`   // 用于埋点
          this.updateState({ key: 'targetCouponInfo', value: targetCoupon || {} })
        } else {
          // 如果targetCoupon为空，说明这张券已经失效，需要重新更新登陆的券标签
          // console.log('--这张券已经失效', currentCouponId)
          this.$toast(this.language.SHEIN_KEY_PWA_24107)
          this.updateState({ key: 'couponFilterLabelData', value: data || {} })
          this.handleCartFilterLabels()
          this.updateState({ key: 'filterType', value: 'none' })
          this.updateState({ key: 'targetCouponInfo', value: {} })
        }
      }
    },
    /**
     * 设置购物车优惠助手默认表现
     * 券助手展示条件：
     * 1. 已登录
     * 2. OpenCartCouponHelpernew.param 的值不为 CloseCartCouponHelpernew
     * 3. CartCouponHelpernew.param 的值中包含 CouponHelperTypenew=show
     * 4. cartSelect.param.CouponSelectType的值是为couponhelper或both
     * 5. 券助手接口返回数据中有商品券，且不全为免邮券（wikiId=1311897633)
     * 6. (1) Cartcouponadd.cart_coupon_entrance_log为off，此时券权益和券凑单与券助手互斥，且未命中券权益和券凑单场景，即CouponAddonStatus为空
     *    (2) Cartcouponadd.cart_coupon_entrance_log为on，此时券权益和券凑单与券助手共存
     */
    async setCartCouponHelperDefault () {
      const closeCouponHelper = this.cartAbtInfo['OpenCartCouponHelpernew']?.param == 'CloseCouponHelpernew'
      const showCouponHelper = (this.cartAbtInfo['CartCouponHelpernew']?.param || '')?.split('&').includes('CouponHelperTypenew=show')

      if (!this.loginStatus || closeCouponHelper || !showCouponHelper) {
        this.showCouponHelperEnter = false
        this.updateBottomActivityConfig({ couponIsReady: true })
        return
      }
      this.updateState({
        key: 'isFetchingCouponInfo',
        value: true
      })
      let res = {}
      if (this.useBffApi.cart_v4) {
        res = await geCartCouponList({ enableCouponCmp: 0 })
      } else {
        res = await getCouponListForCart()
      }
      this.updateState({
        key: 'isFetchingCouponInfo',
        value: false
      })
      let isHasCoupons = true
      let is_satisfied = ''
      if (res.code == 0) {
        // 券筛选时，当商品信息发生变化，更新targetCouponInfo
        if (['select', 'both'].includes(this.couponSelectType)) {
          this.updateTargetCoupon(res?.info || {})
        }
        this.updateState({ key: 'couponInfo', value: res?.info || {} })
        let { usableCouponList = [], disabledCouponList = [] } = res.info || {}
        isHasCoupons = usableCouponList.filter(item => !isFreeShippingCoupon(item)).length > 0 || disabledCouponList.filter(item => !isFreeShippingCoupon(item)).length > 0
        is_satisfied = usableCouponList.length > 0 && disabledCouponList.length > 0 ? 'part' 
          : usableCouponList.length > 0 ? 'all'
            : disabledCouponList.length > 0 ? 'none'
              : ''
      } else {
        isHasCoupons = false
      }

      this.updateBottomActivityConfig({ couponIsReady: true })

      if (!isHasCoupons || this.couponSelectType === 'select' || (this.CouponAddonStatus && !this.cartCouponEntranceLog) || this.isShowNewPromoBar) {
        this.showCouponHelperEnter = false
      } else {
        this.showCouponHelperEnter = true
        daEventCenter.triggerNotice({ 
          daId: '1-8-7-3',
          extraData: { is_satisfied }
        })
      }
      daEventCenter.triggerNotice({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'cart_coupon_entrance_log',
          type: this.CouponAddonStatus
            ? this.CouponAddonStatus === 'couponAddon' ? '1' : '2'
            : '3'
        }
      })
    },
    handlerCouponSaveToast() {
      if (typeof window === 'undefined') return

      const couponData = getCouponDataForAddOnItem()
      handlerCouponSaveToastByBff({
        language: this.language,
        cartAbtInfo: this.cartAbtInfo,
        cartInfo: this.cartInfo,
        couponInfo: couponData?.couponInfo,
      }, {
        $toast: this.$toast,
      })
    },
    handlePageView: function (cartlists){
      this.setCartData(cartlists)
      window.sessionStorage && window.sessionStorage.removeItem('page_from')

      window.TPM?.publish('viewcart', {
        carts: cartlists.carts,
        currency: cartlists.env?.Currency,
        prices: { totalPrice: cartlists.totalPrice }
      })
      this.pageOnload(SaPageInfo)
      // 促销活动结果曝光
      daEventCenter.triggerNotice({
        daId: '1-8-1-14',
        extraData: {
          activity_method: judgeActivityIsExist(cartlists.carts, [3, 10])
        }
      })
      this.handleFirstScreenProducts()
      setTimeout(() => {
        this.handleIncentiveStart()
      }, 0)
    },
    handleFirstScreenProducts(){
      if(window.screenTop) {
        daEventCenter.triggerNotice({
          daId: '1-8-3-22',
          extraData: {
            products_count: '-'
          }
        })
        return
      }
      setTimeout(()=>{
        const documentOffsetHeight = document.body.offsetHeight
        let firstScreenProducts = 0
        $('.cart-list-item').each((i, element) => {
          if ($(element)[0].getBoundingClientRect().top + 15 <= documentOffsetHeight) {
            firstScreenProducts++
          }
        })
      
        daEventCenter.triggerNotice({
          daId: '1-8-3-22',
          extraData: {
            products_count: firstScreenProducts
          }
        })
      }, 800)
    },
    handleIncentiveStart() {
      if(typeof window == 'undefined') return
      daEventCenter.triggerNotice({
        daId: '1-8-7-60',
        extraData: {
          benefitpop_available_point: this.inducementSortList.slice(0, 3).map(item => item.type).join(',') || '-',
          type: 'benefit_pop'
        }
      })
      if (!this.canShowIncentivePopover) return
      if(this.cartAbtInfo?.IncentiveInform?.param?.IncentiveInformPop == 'off') return
      if(this.inducementSortList.length < 1) return
      this.renderCartListAll = true
      for(let i of this.inducementSortList) {
        if(!this.showIncentivePopover && i.countDown && isTimestampWithinDuration(i.countDown, this.incentiveTimeConfig?.leftCountDownTime)) {
          if(!this.showIncentivePopover) {
            this.updateState({
              key: 'showIncentivePopover',
              value: true
            })
          }
          return
        }
      }
      detectUserInteraction({
        noInteractionTimeout: this.incentiveTimeConfig?.noInteractionTimeout || 3000, // 单位为毫秒
        scrollThreshold: 2, // 屏幕高度的倍数
        onNoInteraction: () => {
          if(!this.showIncentivePopover && !this.showIncentiveProductListDialog) {
            this.updateState({
              key: 'showIncentivePopover',
              value: true
            })
          }
          console.log('用户在首次进入3秒内没有交互。')
        },
        onScrollThresholdReached: () => {
          if(!this.showIncentivePopover && !this.showIncentiveProductListDialog && !this.batchActive && window.SaPageInfo.page_name == 'page_cart') {
            this.updateState({
              key: 'showIncentivePopover',
              value: true
            })
          }
          console.log('用户滚动超过了两屏。')
        }
      })
    },
    GetQueryString (name, referer) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      const r = referer.match(reg)
      if (r != null) return unescape(r[2])
      return ''
    },
    resetSaPageInfo: function () {
      let pageFrom = ''
      // from button bar
      const f = this.$route?.query?.f || ''
      if (f) {
        if (f == 'btb') {
          pageFrom = 'button_bar'
        } else {
          pageFrom = f
        }
      } else {
        pageFrom = window.SaPageInfo.page_name || 'page_other'
      }
      let shop_id = ''
      if(document.referrer) {
        const referrerSearch = document.referrer.split('?')[1] || ''
        shop_id = this.GetQueryString('store_code', referrerSearch)
      }
      const getItemTypeNum = () => {
        return this.cartInfo?.carts?.reduce((acc, item) => {
          if (!item.isSoldOutItem) {
            acc.row_count++
            if (item.isShowOnlyXLeftBelt) {
              acc.special_count++
            }
            if (item.isShowSoldOutBelt) {
              acc.almostsoldout++
            }
            if (item.isMallSwitchGoods) {
              acc.mallswitch++
            }
            if (item.isSaleAttrSwitchGoods) {
              acc.skuswitch++
            }
          }
          return acc
        }, {
          row_count: 0,
          special_count: 0,
          almostsoldout: 0,
          mallswitch: 0,
          skuswitch: 0,
        })
      }
      // 路由进入手动刷新数据
      SaPageInfo = {
        page_id: 8,
        page_name: 'page_cart',
        page_param: {
          is_combine: 1,
          page_from: pageFrom,
          store_code: shop_id,
          mall_code: this.cartInfo?.mall_list?.map(item => item.mall_code).join(',') || '',
          label_id: this.filterType == 'none' ? 0 : this.filterType,
          all_row_count: this.cartInfo?.carts?.length || 0,
          ...getItemTypeNum(),
        },
        start_time: new Date().getTime()
      }
      console.log('SaPageInfo', SaPageInfo)
      window.PageGroupOverview = '购物流程'
      window.PageGroupType = 'cart'
      window.PageGroup = '购物车页'
    },
    initAbtInfo: function () {
      return this.fetchCartAbtInfo({ posKeys: posKeys.join(','), newPosKeys: newPosKeys.join(), SaPageInfo })
    },
    // 曝光事件
    exposeEvents () {
      if(this.daEventExpose){
        this.daEventExpose.reset('page-cart-expose-events')
        this.$nextTick(() => {
          this.daEventExpose.update('page-cart-expose-events')
        })
        return
      }

      this.daEventExpose = daEventCenter.getExposeInstance()
      this.$nextTick(() => {
        this.daEventExpose.subscribe({
          keycode: 'page-cart-expose-events`1-8-7-2|1-8-7-6|1-8-2-1|1-8-3-4|1-8-5-1|1-8-3-5|1-8-5-10|1-8-5-18|1-8-5-21|1-8-5-19|1-8-5-28'
        })
      })
    },
    handleQueryCartData (cartlists) {
      this.$nextTick(() => {
        this.exposeEvents()
        // todo 取消页面进入强制刷新推荐
        if (this.$refs?.recommendWrapper) {
          // this.$refs.recommendWrapper.changeReload(true)
        }
        const n = cartlists.carts
        markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })

        if (n && n.length > 0) {
          this.$nextTick(() => {
            // 买折限购报错
            let ids = getQueryString({ key: 'isDiscountLimit' }) || getQueryString({ key: 'isFlashLimit' })
            if (ids) {
              ids = ids.split('_').map(val => +val)
              setTimeout(function () {
                cartEmphasizePromotionAll( ids, { shake: true, changeColor: true, scroll: true })
              }, 1)
            }
          })
        }
      })
    },
    /**
     * @description 空车客户端降级, 获取未支付订单数据 pageId=1377245850
     */
    async fetchUnPaidOrdersInfo() {
      if(this.cartAbtInfo?.EmptyCartUnpaidOrder?.param?.empty_cart_unpaidOrder == 'off') return
      if(this.unPaidOrderInfo?.orderData?.billno) return
      if(!this.loginStatus) return
      let data = await queryLatestUnPaidOrderInfo()
      if(data?.orderData?.billno) {
        this.updateState({
          key: 'unPaidOrderInfo',
          value: data
        })
      }
    },
    // 是否有加车动作
    isHaveAddCart() {
      const beforeActivateCarts = this.cartInfo?.cartSumQuantity || this.carts?.length || 0
      const newActivateCarts = this.rootCartInfo?.sum || this.rootCartInfo?.carts?.length || 0
      const haveAddCart = newActivateCarts > beforeActivateCarts // 有加车动作（包含了从无到有）
      const haveItemsToEmpty = beforeActivateCarts > 0 && newActivateCarts === 0 // 是否从有到无
      if(haveAddCart || haveItemsToEmpty) {
        // 刷新recommendWrapper内的推荐位
        this.shouldReloadRecommend = true
        this.$nextTick(() =>{
          window.scrollTo({ top: 0 })
        })
      } else {
        this.$nextTick(() =>{
          window.scrollTo({ top: this.scrollPosition || 0 })
        })
      }
    },
    cartListIsMounted(){
      this.cartListMounted = true
      if(this.hasFetchCart){
        this.pageLoading = false
      }
      this.initMonitorUserInteraction()
    },
    noMoreItemRender(){
      // if(this.carts?.length <= 6) return
      this.rendSupportInfo = true
    },
    handleRecommendReloadSucess() {
      this.shouldReloadRecommend = false
      this.hiddenRecommend = false
    },
    renderRecommend() {
      requestAnimationFrame(() => {
        if (this.$refs?.recommendWrapper) {
          this.needRender = false
          this.$refs.recommendWrapper.toRender()
        } else {
          this.needRender = true
        }
      })
    },
    paymentFrontShowExpose() {
      if (!this.locals.IS_RW) {
        daEventCenter.triggerNotice({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'if_pay_methods',
            type: (this.payShowStyleA || this.payShowStyleB) ? 1 : 0
          },
        })
      }
    },
    scrollToTopOnQuery() {
      const query = this.$route.query
      if (query.from === 'cart_floor' && String(query.to) === '0' && this.carts.length) {
        if (this.cartfloorInform) {
          this.updateState({
            key: 'showCartFloorDrawer',
            value: true
          })
          return
        }
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.$router.replace({
          ...this.$route,
          query: {},
        })
      }
    },
    handleAddSuccess() {
      this.fetchCarts()
    },
    // TR-38897购物车低效用户引导至推荐
    initMonitorUserInteraction() {
      if (typeof window == 'undefined') return
      this.$nextTick(() => {
        const { style, line, time } = this.cartRecommendGuideConfig
        const showRecommendGuide = () => {
          if (style == 'A') {
            const recommendInView = checkRecommendInView()
            // 如果已经滚动至推荐位，就不需要显示气泡了
            if (recommendInView) return
            // 如果有底部活动券融合起泡，就不展示这个推荐起泡了
            if (!this.canShowPromoGuide) return 
            this.updateState({
              key: 'showRecommendGuideA',
              value: true
            })
          }
          if (style == 'B') {
            const store = this.$store
            insertRecommendGuideB(store)
          }
        }
        this.updateState({
          key: 'monitorUserInteraction',
          value: new MonitorUserInteraction({
            time,
            line,
            showRecommendGuide
          })
        })
        this.monitorUserInteraction.start()
      })
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
  transition: all 0.5s;
}

.vue-slide-layer-bottom-fade-enter,
.vue-slide-layer-bottom-fade-leave-active {
  transform: translate3d(0,100%,@tranlatez-transform1)!important; /* stylelint-disable-line declaration-no-important */
}
.margin-top-32{
    margin-top: .32rem;
}
.cart-container__warpper {
  background: #f6f6f6;
  //padding-top: 1.49rem;
  .iphoneX-block {
    display: none;
  }
  &.isIPhoneX {
    .iphoneX-block {
      display: block;
      width: 100%;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background-color: #fff;
    }
  }
  .S-checkbox{
    margin-right: 20/75rem;
  }
}
.cart-container__recommend {
  margin-top: .267rem;
}
.c-cart__mask-checkout {
  z-index: 9; /* stylelint-disable-line declaration-property-value-blacklist */
  position: fixed;
  background:rgba(0,0,0,0.6);
}
.c-vue-container {
  &.have-data {
    padding-bottom: 5rem;
  }
}

.mcart {
  .font-red {
    color: @sui_color_discount;
  }
  .font-vip {
    color: #A78A45;
  }
  .dmPIqM.S-alert__type-info {
    background-color: @sui_color_prompt_common;
  }
}
ul {
  margin: 0;
  padding: 0
}

.platform-wrapper {
  background: #fff;
  margin: 0 .21rem .42rem .21rem;
  .item-name {
    padding: .315rem;
    font-size: 20px;
    color: #222222;
    font-weight: bold;
    line-height: 22px;
    display: flex;
    align-items: center;
    .local-label {
      padding: .21rem;
      .margin-l(.18rem);
      color: #198055;
      font-size: 12px;
      line-height: 12px;
      font-weight: 200;
      background-color: #F5FCFB;
    }
  }
  .shop-name {
    padding: 0.26666667rem 0.21333333rem;
    display: flex;
    align-items: center;
    .shop-logo {
      width: 30px;
      height: 30px;
    }
    .shop-name_label {
      max-width: 80%;
      line-height: 16px;
      font-size: 16px;
      color: #222;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 .21rem;
    }
    .shop-icon_more {
      font-size: 16px;
    }
  }
}
.famliy-adieu {
  /* rw:begin */
  font-family: Adieu;
}
.sticky-block{
  position: fixed;
  z-index: @zindex-header;
}
.client-only-height{
  min-height: 36/75rem;
}
.sui_icon_more_right_12px{
  width: 12px;
  display: inline-block;
}
.suiiconfont-critical-cart.sui_icon_more_right_12px{
  transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
}
.support-info-cart {
  padding-left: 16/75rem;
  padding-right: 16/75rem;
}
.suiiconfont:not([class*="sui_icon_nav_back_24px"]),
[class*="suiiconfont-critical"]:not([class*="sui_icon_nav_back_24px"]) {
    &[class*="_12px"] {
        font-size: 12px;
    }
    &[class*="_14px"] {
        font-size: 14px;
    }
    &[class*="_15px"] {
        font-size: 15px;
    }
    &[class*="_16px"] {
        font-size: 16px;
    }
    &[class*="_18px"] {
        font-size: 18px;
    }
    &[class*="_20px"] {
        font-size: 20px;
    }
    &[class*="_22px"] {
        font-size: 22px;
    }
    &[class*="_24px"] {
        font-size: 24px;
    }
    &[class*="_28px"] {
        font-size: 28px;
    }
    &[class*="_34px"] {
        font-size: 34px;
    }
    &[class*="_40px"] {
        font-size: 40px;
    }
    &[class*="_75px"] {
        font-size: 75px;
    }
}
.c-skeleton-cart-container {
    background: white;
    position: fixed;
    z-index: 10000; /* stylelint-disable-line declaration-property-value-blacklist */
    width: 10rem;
    height: 100%;
    top: 1.17rem;
}
/* 定义关键帧动画 */
@keyframes highlight {
  /* 动画分四个阶段：从原始色到高亮色，再回到原始色，然后循环两次 */
  0%, 50%, 100% { background: #fff; }
  25%, 75% { background: #FFFBEE; }
}

/* 应用动画到具体目标元素上 */
.highlight-animate {
  /* 整个动画持续 800ms (400ms 高亮 * 2)，不延迟开始，执行一次 */
  animation: highlight 800ms linear 0s 1;
}

.sweep-light {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, transparent, rgba(255,255,255,.6), transparent);
    animation: sweepLight 6s linear infinite;
  }
}
@keyframes sweepLight {
  0% {
      transform: translate(-100%);
  }
  10% {
      transform: translate(100%);
  }
  100% {
      transform: translate(100%);
  }
}
</style>

<style lang="less" scoped>
.cart-container__no-network-empty {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 200001;
}
.hide {
  display: none;
}
</style>
