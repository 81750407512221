var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.render)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-47',
    data: {
      sku_code: _vm.item.id,
      is_checked: _vm.item.is_checked,
      is_change: _vm.isChange,
      scene: _vm.getScene(),
      goods_id: _vm.item.product && _vm.item.product.goods_id,
    },
  }),expression:"{\n    id: '1-8-3-47',\n    data: {\n      sku_code: item.id,\n      is_checked: item.is_checked,\n      is_change: isChange,\n      scene: getScene(),\n      goods_id: item.product && item.product.goods_id,\n    },\n  }"}],ref:"target",class:[
    'estimated-tag',
    'j-estimated-price',
    _vm.primeClassName,
    {
      'estimated-price__with-coupon': _vm.showWithCoupon, 
      'new-cartrow-vision': _vm.isNewCartrowVision
    }
  ],attrs:{"data-id":_vm.item.id},on:{"click":_vm.onClick}},[(_vm.showWithCoupon)?_c('div',{staticClass:"estimated-price-new",style:({
      marginRight: _vm.isShowCartrowEstimatedCountdown && _vm.canShowCountdown ? '4px' : '2px'
    })},[_c('span',{staticClass:"estimated-price-new__amount",domProps:{"innerHTML":_vm._s(_vm.estimatedPriceWithSymbol)}}),_vm._v(" "),_c('i',{staticClass:"line"},[_vm._v("|")]),_vm._v(" "),_c('span',{staticClass:"with-coupon"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_32255))])]):[_c('span',{staticClass:"estimated"},[_vm._v(_vm._s(_vm.estimatedText))]),_vm._v(" "),(_vm.showDiscount)?_c('span',{staticClass:"discount"},[_vm._v(_vm._s(_vm.discountText))]):_vm._e()],_vm._v(" "),(_vm.isShowCartrowEstimatedCountdown && _vm.canShowCountdown)?[(!_vm.showWithCoupon)?_c('i',{staticClass:"line"},[_vm._v("|")]):_vm._e(),_vm._v(" "),(_vm.isNewCartrowVision)?_c('CartTagsCountDown',{attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_vm.timeStamp}}):_c('count-down-time',{attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_vm.timeStamp}})]:_vm._e(),_vm._v(" "),(_vm.estimatedDetailSwitch)?[_c('Icon',{staticClass:"estimated-tag__more-right",attrs:{"name":"sui_icon_more_right_10px","size":"10px","color":_vm.isPremiumMember ? _vm.primeColor : '#FA6338'}})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }