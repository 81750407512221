<template>
  <div
    class="filter-goods-tip"
    @click="viewHighWeightGoods"
  >
    <div class="goods-tip-content">
      <div class="goods-img">
        <img :src="filterHighWeightGoods?.product?.goods_img" />
      </div>
      <span class="tip-text">
        {{ tipText }}
        <count-down-time
          class="count-down-time"
          font-size="12px"
          mode="text"
          not-hide
          not-reload
          :time-stamp="endTimestamp"
        />
      </span>
    </div>
    <div class="view-btn">
      <span class="view-text">{{ language.SHEIN_KEY_PWA_32145 }}</span>
      <Icon
        class="view-icon"
        name="sui_icon_doubledirection_12px"
        size="12px"
        color="#FA6338"
      />
    </div>
  </div>
</template>

<script setup>
import { Icon } from '@shein-aidc/icon-vue2'
import { useStore } from 'public/src/pages/cartNew/utils/index.js'
import { computed, onMounted } from 'vue'
import { template } from '@shein/common-function'
import { LabelKeyMap, promotionTypeIdMap } from '@/public/src/pages/cartNew/utils/cartFilterLabels.js'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

const rootStore = useStore()
const language = computed(() => {
  return rootStore?.state?.cart?.language || {}
})
const filterHighWeightGoods = computed(() => {
  return rootStore?.state?.cart?.filterHighWeightGoods
})
const filterType = computed(() => {
  return rootStore?.state?.cart?.filterType
})
const integrityPromotionInfo = computed(() => {
  return rootStore?.state?.cart?.integrityPromotionInfo
})
const endTimestamp = computed(() => {
  if ([LabelKeyMap.LIMIT_OFF, LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT].includes(filterType.value)) {
    const promotionTypeId = promotionTypeIdMap[filterType.value]
    const timeStamps = Object.values(integrityPromotionInfo.value).filter(item => item?.typeId == promotionTypeId).map(item => item?.end_time)
    return Math.min(...timeStamps) || null
  }
  return null
})
const tipText = computed(() => {
  const realStock = filterHighWeightGoods.value?.product?.real_stock
  if (filterType.value == LabelKeyMap.MARKDOWNS) {
    // 降价类高优商品提示文案
    return realStock < 10
      ? template(realStock, language.value.SHEIN_KEY_PWA_33851)
      : language.value.SHEIN_KEY_PWA_33852
  } else if ([LabelKeyMap.LIMIT_OFF, LabelKeyMap.FLASH_SALE, LabelKeyMap.DISCOUNT_LIMIT].includes(filterType.value)) {
    // 活动类高优商品提示文案
    return realStock < 10
      ? template(realStock, language.value.SHEIN_KEY_PWA_33853)
      : language.value.SHEIN_KEY_PWA_33854
  }
  return ''
})
const viewHighWeightGoods = () => {
  daEvent('click_select_item_information.page_cart')
  const targetDom = document.querySelector(`[data-cart-id="${filterHighWeightGoods.value.id}"]`)?.querySelector('.mshe-item')
  targetDom.scrollIntoView({
    behavior: 'smooth', // 平滑滚动
    block: 'center',    // 垂直居中
  })
  blinkElement(targetDom, '#FFFBEE', 400, 2) // 400ms一次，闪烁2次
}
// 设置闪烁函数
const blinkElement = (el, color, interval, times) => {
  let originalColor = window.getComputedStyle(el).background
  let count = 0
  const timer = setInterval(() => {
    if (count % 2 === 0) {
      el.style.background = color
    } else {
      el.style.background = originalColor
    }
    count++
    if (count >= times * 2) {
      clearInterval(timer)
      el.style.background = originalColor // 确保最终背景颜色为原始颜色
    }
  }, interval)
}
const daEvent = (id) => {
  daEventCenterV2.triggerNotice(({
    id,
    data: {
      type: filterType.value,
      sku_id: filterHighWeightGoods.value?.product?.goods_sn,
    }
  }))
}

onMounted(() => {
  daEvent('expose_select_item_information.page_cart')
})
</script>

<style lang="less" scoped>
.filter-goods-tip {
  display: flex;
  align-items: center;
  min-height: 80/75rem;
  margin: 16/75rem 12/75rem;
  padding: 20/75rem 24/75rem;
  border-radius: 8/75rem;
  border: solid 2/75rem #FFFFFF;
  background: linear-gradient(#FFF6F6, #FFFFFF);
  box-sizing: border-box;
  .goods-tip-content {
    flex: 1;
    display: flex;
    align-items: center;
    .goods-img {
      flex-shrink: 0;
      width: 40/75rem;
      height: 40/75rem;
      overflow: hidden;
      border-radius: 2/75rem;
      margin-right: 16/75rem;
      img {
        width: 100%;
      }
    }
    .tip-text {
      flex: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 24/75rem;
      line-height: 30/75rem;
      .count-down-time {
        display: inline;
        color: #FA6338;
        
      }
    }
  }
  .view-btn {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
    max-width: 150/75rem;
    height: 40/75rem;
    padding: 6/75rem 12/75rem;
    border: solid 1/75rem #FA6338;
    color: #FA6338;
    border-radius: 20/75rem;
    overflow: hidden;
    .view-text {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 24/75rem;
    }
    .view-icon {
      flex-shrink: 0;
    }
  }
}
</style>
