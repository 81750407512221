var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-73',
    data: {
      ..._setup.getExposeAnalysisData()
    }
  }),expression:"{\n    id: '1-8-3-73',\n    data: {\n      ...getExposeAnalysisData()\n    }\n  }"}],ref:"container",staticClass:"cart-rightsFuns-tip_new"},[_c('div',{class:[
      'left-tip', // 存在右侧文案new user或returnee bonus
      {
        'only-one-rights': !_setup.rightItemText && ((_vm.showFreeShipping && !_vm.isFreeReturn) || (!_vm.showFreeShipping && _vm.isFreeReturn)), // 无右侧文案，且仅存在freeshipping或freereturn一个权益：居中展示
        'shipping-and-return': (!_setup.rightItemText && _vm.showFreeShipping && _vm.isFreeReturn) && !_setup.textOverflow, // 无右侧文案，同时存在freeshipping和freereturn权益：以｜平分宽度居中展示，溢出取消平分｜和居中
      }
    ]},[(_vm.showFreeShipping)?_c('div',{staticClass:"free-shipping-rights"},[_c(_setup.FreeShippingIcon),_vm._v(" "),_c('span',{ref:"leftItem1",staticClass:"free-shipping-text"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_32027)+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isFreeReturn)?_c('div',{staticClass:"free-return-rights"},[_c(_setup.FreeReturnIcon),_vm._v(" "),_c('span',{ref:"leftItem2",staticClass:"free-return-text"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_32028)+"\n      ")])],1):_vm._e()]),_vm._v(" "),(_setup.rightItemText)?_c('div',{ref:"rightItem",staticClass:"tip-right"},[_vm._v("\n    "+_vm._s(_setup.rightItemText)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }