<template>
  <div
    v-if="promotionTip"
    class="cart_item-header new-promotion-header"
    :class="[`j-promotion-header-${promotionContent.id}`, {
      'new-cartrow-vision': isNewCartrowVision
    }]"
    :type-id="promotionContent.typeId"
  >
    <!-- 横幅类型 -->
    <div class="new-promotion-header__content">
      <slot></slot>
      <section
        class="cart_item-banner"
        tabindex="0"
        :aria-label="promotionTip"
      >
        <div class="title-action-operation">
          <i
            class="new-promotion-header__icon"
          >
            <component
              :is="[2,28].includes(promotionContent.typeId) ? 'sui_icon_gift_18px':'sui_icon_activity_18px'"
              size="15px"
            />
          </i>
          <div class="promotion-header-content">
            <p
              class="cart_item-tip"
              :class="{'cart_tip-red': changeColor}"
              tabindex="0"
              v-html="promotionTip"
            >
            </p>
            <div
              v-if="integrityPromotionInfo.is_count_down == 1"
              class="client-only-height"
            >
              <ClientOnly>
                <count-down-time :time-stamp="integrityPromotionInfo.end_time" />
              </ClientOnly>
            </div>
          </div>
          <div
            v-if="((promotionContent.action > 0)|| (promotionContent.action == 1 && (promotionContent.typeId != 10 || (promotionContent.typeId == 10 && showFlashSaleEnter)))) && !batchActive && promotionContent.typeId != 11"
            v-enterkey
            class="cart_item-action"
            tabindex="0"
            role="button"
            @click="clickPick"
          >
            <span style="vertical-align: text-bottom">{{ handleText }}</span>
            <sui_icon_more_right_12px
              size="14px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <FullGiftList
      v-if="showFullGiftsList"
      :gift-list="getGiftLift"
      :promotion-id="fullGiftId"
      :disabled-tip="promotionContent.action !=2 ? promotionTip : ''"
      :type="1"
      :promotion-type="promotionType"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ClientOnly from 'vue-client-only'
import { handleOrderOrSingleIsMeetOrOver } from '../../utils'
import FullGiftList from './fullGiftsList.vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { isObject, template } from '@shein/common-function'
import { sui_icon_more_right_12px, sui_icon_gift_18px, sui_icon_activity_18px } from '@shein-aidc/icon-vue2'
const { langPath } = gbCommonInfo

const promotionProductTypeMap = {
  2: '1',
  28: '1',
  13: '2',
}

export default {
  name: 'PromotionTplVue',
  components: {
    ClientOnly,
    FullGiftList,
    sui_icon_more_right_12px,
    sui_icon_activity_18px,
    sui_icon_gift_18px
  },
  props: {
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showStoreTitle: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('cart', [ 'language', 'batchActive', 'cartAbtInfo', 'isSSR', 'locals']),
    ...mapGetters('cart', ['carts', 'cartPromotionMap', 'iconPrefix', 'isNewCartrowVision']),
    promotionContent () {
      return this.integrityPromotionInfo.content || {}
    },
    promoStoreTitle() {
      if (!this.showStoreTitle) return ''
      const product = this.carts.find(item => item.store_code == this.integrityPromotionInfo.store_id)
      return product?.store_title ? `<span class="store-in-promo">${template(product.store_title, this.language.SHEIN_KEY_PWA_33677)}</span>` : ''
    },
    promotionTip () {
      const { tip } = this.promotionContent
      let originTip = isObject(tip) ? tip[this.isChecked ? 1 : 0] : tip
      return originTip + this.promoStoreTitle
    },
    /**
     * 通过Abt判断闪购10是否展示跳转入口
     */
    showFlashSaleEnter() {
      const typeId = this.promotionContent.typeId
      const FlashSaleTypeIds = ['10']

      if (FlashSaleTypeIds.includes('' + typeId)) {
        /**
         * PromotionIcon 推全 ShowPromotionIcon
         */
        // return this.cartAbtInfo['PromotionIcon'] && this.cartAbtInfo['PromotionIcon'].param == 'ShowPromotionIcon' ? true : false
        return true
      } else {
        return false
      }
    },
    handleText () {
      if (this.promotionContent.action == 1) {
        return this.language.SHEIN_KEY_PWA_15091
      } else if (this.promotionContent.action == 2) {
        const isPromoGoods = this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => (p.promotion_product_type == '1' || p.promotion_product_type == '2')))
        if (isPromoGoods) {
          return this.language.SHEIN_KEY_PWA_15831
        } else {
          return this.language.SHEIN_KEY_PWA_15830
        }
      } else {
        return ''
      }
    },
    countDownData () {
      if (this.promotionContent && this.promotionContent.countDown) {
        return this.promotionContent.countDown
      } else {
        return ''
      }
    },
    changeColor(){
      let LimitIds = []
      let promotionGroupList = this.integrityPromotionInfo.list || []
      promotionGroupList.forEach( cartItem => {
        let result = handleOrderOrSingleIsMeetOrOver(cartItem, this.integrityPromotionInfo, true)
        if(result && !LimitIds.includes(cartItem.promotionGroupId)){
          LimitIds.push(cartItem.promotionGroupId)
        }
      })

      if( LimitIds.length ){
        return true
      }
      return false
    },
    showFullGiftsList(){
      return !this.isPicked && this.getGiftLift.length && !this.batchActive
    },
    getGiftLift(){
      return this.promotionContent.showFullGiftList || []
    },
    isPicked() {
      const { id } = this.promotionContent || {}
      return this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == promotionProductTypeMap[this.promotionType] && p.promotion_id == id))
    },
    // 选择赠品是否有效
    isChecked() {
      const { id } = this.promotionContent || {}
      return this.integrityPromotionInfo.list.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == promotionProductTypeMap[this.promotionType] && p.promotion_id == id && item.is_checked))
    },
    // 使用的全场活动id
    fullGiftId () {
      return this.integrityPromotionInfo.promotion_id
    },
    promotionType() {
      return this.promotionContent.typeId
    },
  },
  mounted () {
    daEventCenter.triggerNotice({
      daId: '1-8-5-1',
      extraData: this.getSaData()
    })
  },
  methods: {
    ...mapMutations('cart', ['changeAppendageDrawerStatus', 'updateState']),
    ...mapMutations(['changeRootSLoadingStatus']),
    async clickPick () {
      if (this.batchActive) return
      const { action, id: promotionId, typeId  } = this.promotionContent
      const promotionItem = this.integrityPromotionInfo
      const addGoodToReach = promotionItem.goods?.sc_id && action == 1
      promotionItem.scId = promotionItem.goods?.sc_id || ''
      const buyGiftPick = typeId == 2 && (action == 2)
      const addBuyPick = typeId == 13 && (action == 2)
      const partFullGifts = typeId == 28 && (action == 2)

      const sendClickAnalysis = () => {
        daEventCenter.triggerNotice({
          daId: '1-8-5-2',
          extraData: this.getSaData()
        })
      }

      if(
        [9, 25, 20, 1, 15].includes(+typeId) || 
          (typeId == 14 && this.integrityPromotionInfo?.goods?.type != 3) ||
          ([13, 2, 28].includes(+typeId) && action == 1)
      ) {
        this.openToAddOn({
          typeId,
          promotion_id: promotionId,
        })
        sendClickAnalysis()
        return
      }

      if (buyGiftPick) {
        this.changeAppendageDrawerStatus({ status: 'buyGiftShow', promotionId: promotionId })
        sendClickAnalysis()
      } else if (addBuyPick) {
        this.changeAppendageDrawerStatus({ status: 'addBuyShow', promotionId: promotionId })
        
        sendClickAnalysis()
      } else if (partFullGifts) {
        this.changeAppendageDrawerStatus({ status: 'fullGiftShow', promotionId: promotionId })
        
        sendClickAnalysis()
      } else if (addGoodToReach) { // TODO: 满足不门槛后再点击?
        sendClickAnalysis()
        // const newWin = window.open('', '_self')
        markPoint('toNextPageClick', 'public')
        this.changeRootSLoadingStatus({ show: true, maskTransparent: true })
        const url = await PromotionUtils.promotionLink(promotionItem)
        this.changeRootSLoadingStatus({ show: false })
        if(url){
          this.$router.push(url)
        }
        // newWin.location = url
      } else if (this.showFlashSaleEnter) {
        sendClickAnalysis()
        // const newWin = window.open('', '_self')
        markPoint('toNextPageClick', 'public')
        this.$router.push(langPath + `/flash-sale.html?mallCode=${promotionItem.mall_code}`)
      }
    },
    getCategoryParam () {
      let flashSaleItems = this.carts.filter(item => item.promotionModule?.content?.typeId == 10)
      let catIdsArray = flashSaleItems.map(item => item.product.cat_id)
      return catIdsArray.length ? 'cat_id=' + decodeURIComponent(catIdsArray.join(',')) : ''
    },
    openToAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    },
    getSaData() {
      const { id, typeId } = this.promotionContent
      const { isFullPromotion } = this.cartPromotionMap[id] || {}
      return {
        id,
        typeId,
        isFullPromotion,
        is_brand_product: this.integrityPromotionInfo?.goods?.type,
        is_couponbag: typeId == 20 ? (this.integrityPromotionInfo?.couponNum > 1 ? 1 : 0) : '-',
        is_member_gift: 0,
        is_all_store: this.integrityPromotionInfo?.all_store_flag,
        store_id: this.integrityPromotionInfo?.store_id,
      }
    }
  }
}
</script>
<style lang="less" scoped>
.new-promotion-header {
  display: block;
  &__content {
    display: flex;
  }
  .cart_item-tip /deep/ .store-in-promo {
    color: #626262;
    margin-left: 4/37.5rem;
  }
}
</style>
